/* eslint-disable no-unused-vars */
import React from "react";
import { Formik, Form } from "formik";
import { Button, Stack } from "react-bootstrap";
import * as yup from "yup";
import toast from "react-hot-toast";

import AuthService from "services/auth.service";

import FormField from "components/Inputs/FormField";

const Login = (props) => {
  const initialValues = {
    email: "",
    password: "",
  };

  const schema = yup.object({
    email: yup.string().required("Pole wymagane"),
    password: yup.string().required("Pole wymagane."),
  });

  const handleSubmit = (values, { setSubmitting }) => {
    AuthService.login(values.email, values.password).then(
      (response) => {
        if (response.success === false) {
          toast.error("Błąd logowania");
          setTimeout(() => setSubmitting(false), 3 * 1000);
        }
        if (response.success === true) {
          window.location.reload(false);
        }
      },
      (error) => {
        const resMessage = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
        toast.error(`Error: ${resMessage}`);
        setTimeout(() => setSubmitting(false), 3 * 1000);
      }
    );
  };

  return (
    <section className='vh-100 gradient-custom'>
      <div className='container py-5 h-100'>
        <div className='row d-flex justify-content-center align-items-center h-100'>
          <div className='col-12 col-md-8 col-lg-6 col-xl-5'>
            <div className='card bg-dark text-white' style={{ borderRadius: "1rem" }}>
              <div className='card-body p-5 text-center'>
                <div className='mb-md-5 mt-md-4 pb-5'>
                  <h2 className='fw-bold mb-2 text-uppercase'>Logowanie</h2>
                  <p className='text-white-50 mb-5'>Podaj login i hasło!</p>
                  <div className='text-start'>
                    <Formik initialValues={initialValues} noValidate validationSchema={schema} onSubmit={(values, { setSubmitting }) => handleSubmit(values, { setSubmitting })}>
                      {({ values, isValid, isSubmitting }) => (
                        <Form>
                          <Stack gap={3}>
                            <FormField required label='Email' type='text' name='email' />
                            <FormField required label='Hasło' type='password' name='password' />
                            <Button size='lg' className='px-5 mt-5' type='submit' disabled={!isValid || isSubmitting}>
                              Zaloguj
                            </Button>
                          </Stack>
                        </Form>
                      )}
                    </Formik>
                  </div>
                </div>

                <div></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Login;
