/* eslint-disable no-unused-vars */
import { useQuery, useMutation, useQueryClient } from "react-query";
import { request } from "../utils/axios";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { useContext } from "react";
import { WebsocketContext } from "../context/WebsocketContext";

const updateContainer = (item) => {
  return request({ url: `/containers?id=${item.id}`, method: "patch", data: item });
};

export const useUpdateContainerData = () => {
  const queryClient = useQueryClient();
  let navigate = useNavigate();
  const [ready, send] = useContext(WebsocketContext);
  return useMutation(updateContainer, {
    onError: (error) => {
      toast.error(`Error on background update: ${error.message}`);
    },

    onSuccess: (data, variables) => {
      toast.success("Pomyślnie zmieniono status");

      setTimeout(() => {
        if (variables?.containers?.[0]?.type === 1 || variables?.containers?.[0]?.type === 2) {
          navigate("/schedule");
        } else {
          navigate("/delivery");
        }
      }, 500);
      if (ready) {
        send(JSON.stringify({ operation: "invalidate", entity: ["deliveries"] }));
      }

      queryClient.invalidateQueries(["deliveries"]);
    },
  });
};
