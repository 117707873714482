import React, { useState, useEffect } from "react";
import { Modal, Button, Badge, Stack, Form as BForm, Col } from "react-bootstrap";
import { Formik, Form, Field } from "formik";
import FormRatings from "form-ratings";
import * as yup from "yup";

import FormField from "components/Inputs/FormField";
import FormDebug from "components/debug/FormDebug";
import Icons from "components/Icons/";

import { useUpdateContainerData } from "queries/useContainerData";

const UnloadingProduction = (props) => {
  const [initialValues, setInitialValues] = useState({
    id: null,
    type: 4,
    acceptanceType: 3,
    freshnessQuality: 0,
    bloodQuantity: 0,
    waterQuantity: 0,
    foreignBodies: "false",
    foreignBodiesComment: "",
  });
  const { onHide, data } = props;

  const { mutate } = useUpdateContainerData();

  useEffect(() => {
    if (data?.data !== undefined) {
      setInitialValues({
        id: data?.data?.id,
        type: 6,
        acceptanceType: data?.status,
        freshnessQuality: 0,
        bloodQuantity: 0,
        waterQuantity: 0,
        foreignBodies: "false",
        foreignBodiesComment: "",
      });
    }
  }, [data]);

  const schema = yup.object({
    freshnessQuality: yup.number().required("Pole wymagane"),
    bloodQuantity: yup.number().required("Pole wymagane"),
    waterQuantity: yup.number().required("Pole wymagane"),
  });

  return (
    <Modal {...props} onHide={onHide} aria-labelledby='contained-modal-title-vcenter' centered>
      <Modal.Header closeButton>
        <Modal.Title className='d-flex align-middle' id='contained-modal-title-vcenter'>
          <Icons.Warning /> <span className='modal-title'>Zarejestrować rozładunek surowca do produkcji?</span>
        </Modal.Title>
      </Modal.Header>
      <Formik
        initialValues={initialValues}
        validationSchema={schema}
        enableReinitialize
        onSubmit={(values) => {
          mutate(values);
          setTimeout(() => {
            onHide();
          }, 500);
        }}>
        {({ values, isValid, isSubmitting }) => (
          <Form>
            <Modal.Body>
              <Stack>
                <div>
                  <p>
                    Kontener # {data?.data.containerNumber}: ({data?.data?.rawMaterialString !== undefined ? JSON.parse(data?.data.rawMaterialString).shortName : ""})
                  </p>
                  <ul>
                    {data?.data?.suppliers?.map((supplier, index) => {
                      return (
                        <li key={index}>
                          {supplier.weight}kg od {supplier?.nameString !== undefined ? JSON.parse(supplier.nameString).shortName : ""}
                          {"  "}
                          <Badge bg='warning' text='dark'>
                            {supplier.snc}
                          </Badge>
                        </li>
                      );
                    })}
                  </ul>
                </div>

                <Col sm={6}>
                  <Stack gap={3}>
                    <Stack direction='horizontal' gap={4}>
                      <label className='required' htmlFor='freshnessQuality'>
                        Świeżość:
                      </label>
                      <Field name='freshnessQuality' className='ms-1' as={FormRatings} />
                    </Stack>
                    <Stack direction='horizontal' gap={4}>
                      <label className='required' htmlFor='bloodQuantity'>
                        Ilość krwi:
                      </label>
                      <Field name='bloodQuantity' as={FormRatings} />
                    </Stack>
                    <Stack direction='horizontal' gap={4}>
                      <label className='required' htmlFor='waterQuantity'>
                        Ilość wody:
                      </label>
                      <Field name='waterQuantity' as={FormRatings} />
                    </Stack>
                    <div role='group' aria-labelledby='my-radio-group'>
                      <Stack direction='horizontal' gap={3}>
                        <label className='required' htmlFor='foreignBodies'>
                          Ciała obce?
                        </label>
                        <Stack direction='horizontal' gap={3}>
                          <Field as={BForm.Check} type='radio' name='foreignBodies' value={"true"} />
                          Tak
                        </Stack>
                        <Stack direction='horizontal' gap={3}>
                          <Field as={BForm.Check} type='radio' name='foreignBodies' value={"false"} />
                          Nie
                        </Stack>
                      </Stack>
                    </div>
                    {values.foreignBodies === "true" && <FormField label='Rodzaj ciał obcych' type='text' name='foreignBodiesComment' />}
                  </Stack>
                </Col>

                <FormDebug values={values} />
              </Stack>
            </Modal.Body>
            <Modal.Footer>
              <Button type='submit' disabled={!isValid || isSubmitting}>
                Zapisz
              </Button>
              <Button onClick={onHide}>Anuluj</Button>
            </Modal.Footer>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

export default UnloadingProduction;
