import React from "react";
import logo from "assets/img/logo.png";

const Logo = () => (
  <div className='logo-wrapper'>
    <img src={logo} alt='logo' />
  </div>
);

export default Logo;
