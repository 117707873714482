/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useRef, useCallback, useEffect } from "react";
import debounce from "lodash.debounce";
import { Link } from "react-router-dom";
import { Stack, Button, Col, Row, Spinner } from "react-bootstrap";
import { Formik, Form } from "formik";
import moment from "moment";
import * as Page from "pages";
import FormField from "components/Inputs/FormField";
import FormSelect from "components/Inputs/FormSelect";
import FormDebug from "components/debug/FormDebug";
import WeekInput from "components/Inputs/WeekInput";

import ApiError from "components/error";
import Icons from "components/Icons";
import AuthService from "services/auth.service";

import { useDeliveriesData } from "queries/useDeliveriesData";
import { useSchedule } from "context/ScheduleContext";

let weekNow = Number(moment().format("W"));

const options = [
  { value: "", label: "Wszystkie" },
  { value: "0", label: "Poniedziałek" },
  { value: "1", label: "Wtorek" },
  { value: "2", label: "Środa" },
  { value: "3", label: "Czwartek" },
  { value: "4", label: "Piątek" },
  { value: "5", label: "Sobota" },
  { value: "6", label: "Niedziela" },
];

const Schedule = () => {
  const {
    query,
    setQuery,
    weekNumber,
    setWeekNumber,
    topFilters,
    setTopFilters
  } = useSchedule();

  const formRef = useRef();
  const { data, isLoading, isError, error, refetch } = useDeliveriesData(query);

  const changeHandler = () => {
    let values = formRef.current.values;
    setTopFilters(values);
  };

  const debouncedChangeHandler = useCallback(debounce(changeHandler, 300), []);

  useEffect(() => {
    if (formRef.current) {
      formRef.current.setFieldValue("week", weekNumber - weekNow);
      debouncedChangeHandler();
    }
  }, [weekNumber]);

  useEffect(() => {
    let query = "?type[]=1&type[]=2" + "&week=" + topFilters.week;

    if (topFilters.day) {
      query += "&day=" + topFilters.day;
    }

    if (topFilters.snc) {
      query += "&snc=" + topFilters.snc;
    }

    setQuery(query);
  }, [topFilters]);

  useEffect(() => {
    refetch();
  }, [query]);

  return (
    <section className='registry-delivery-wrapper' style={{ height: "100vh" }}>
      <Stack gap='5' className='pt-5' style={{ height: "100%" }}>
        <Formik initialValues={topFilters} noValidate innerRef={formRef} enableReinitialize>
          {({ values }) => (
            <Form onChange={debouncedChangeHandler} className='container-fluid'>
              <Row>
                <FormSelect callback={debouncedChangeHandler} validOn={false} as={Col} label='Dzień tygodnia' name='day' options={options} />
                <FormField as={Col} label='SCN' validOn={false} autocomplete='off' type='text' name='snc' />
                <Col>
                  <WeekInput label='Tydzień' validOn={false} reset={values.week} weekNumber={weekNumber} setWeekNumber={setWeekNumber} />
                  <div className='d-flex justify-content-center'>
                    <a href='#' className='text-decoration-none' onClick={() => setWeekNumber(weekNow)} target='_self'>
                      ( przejdź do bieżącego tygodnia )
                    </a>
                  </div>
                </Col>
              </Row>
              <FormDebug onChange={debouncedChangeHandler} values={values || {}} />
            </Form>
          )}
        </Formik>
        {weekCheck(weekNumber)}
        <CheckWrapper isLoading={isLoading} isError={isError} error={error}>
          <Page.ScheduleTable data={data?.tableData || []} refetch={refetch} />
        </CheckWrapper>
      </Stack>
    </section>
  );
};

export default Schedule;

const CheckWrapper = (props) => {
  const { isLoading, isError, children, error } = props;

  if (isLoading) {
    return (
      <Row className='justify-content-md-center mt-5'>
        <Spinner animation='border' role='status'>
          <span className='visually-hidden'>Wczytuje...</span>
        </Spinner>
      </Row>
    );
  }
  if (isError) {
    return <ApiError error={error} />;
  }

  return <>{children}</>;
};

const weekCheck = (week) => {
  if (week < weekNow) {
    return;
  }
  return (
    <Stack direction='horizontal' gap='3'>
      {AuthService.userCan("dodaj_dostawe_surowca") && (
        <Link to={"new"}>
          <Button variant='primary'>
            <Icons.Plus /> Dodaj dostawę
          </Button>
        </Link>
      )}
    </Stack>
  );
};
