/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-key */
import React, { useState, useEffect } from "react";
import { useTable, usePagination } from "react-table";
import { useDictionariesData, useUpdateDictionaryData, useDeleteDictionaryData } from "queries/useDictionaryData";
import BTable from "react-bootstrap/Table";
import { Stack, Button, Row, Col, ButtonToolbar, ButtonGroup, DropdownButton, Dropdown, Spinner } from "react-bootstrap";
import * as Modal from "components/Modal";
import ApiError from "components/error";
import AuthService from "services/auth.service";
import Select from "react-select";

const Dictionary = () => {
  const [dictionaryType, setDictionaryType] = useState(1);
  const [column, setColumn] = useState([]);
  const [data, setData] = useState([]);
  const [skipPageReset, setSkipPageReset] = useState(false);
  const [isOpenDriver, setOpenDriver] = useState(false);
  const [isOpenVehicle, setOpenVehicle] = useState(false);
  const [isOpenRaw, setOpenRaw] = useState(false);
  const [isOpenContractor, setOpenContractor] = useState(false);

  const { mutate: update } = useUpdateDictionaryData();
  const { mutate: _delete } = useDeleteDictionaryData();
  const { data: _data, isLoading, refetch, isRefetching, isError, error } = useDictionariesData(dictionaryType);

  const onSubmit = (values) => {
    const body = (({ id, type, ...body }) => body)(values);
    update({ id: values.id, data: { type: values.type, body: JSON.stringify(body) } });
  };

  const onDelete = (values) => {
    const body = (({ id, type, ...body }) => body)(values);
    _delete({ id: values.id, data: { type: values.type, body: JSON.stringify(body) } });
  };

  useEffect(() => {
    if (_data !== undefined) {
      setData(_data?.map((s) => ({ ...JSON.parse(s.body), id: s.id, type: s.type })));
    }
  }, [_data, dictionaryType, isRefetching]);

  useEffect(() => {
    switch (dictionaryType) {
      case 1:
        return setColumn(vehicle);
      case 2:
        return setColumn(driver);
      case 3:
        return setColumn(rawMaterial);
      case 4:
        return setColumn(contractor);
      default:
        return setColumn(vehicle);
    }
  }, [dictionaryType]);

  const Actions = ({ cell }) => {
    let showButtons = true;
    switch (dictionaryType) {
      case "Pojazdy":
        showButtons = AuthService.userCan("edytuje_pojazdy");
        break;
      case "Kierowcy":
        showButtons = AuthService.userCan("edytuje_kierowcow");
        break;
      case "Surowiec":
        showButtons = AuthService.userCan("edytuje_surowce");
        break;
      case "Kontrahenci":
        showButtons = AuthService.userCan("edytuje_kontrahentow");
        break;
    }
    if (!showButtons) {
      return [];
    }
    return (
      <Stack direction='horizontal' gap={3}>
        <Button onClick={() => onSubmit(cell.row.original)}>Zapisz</Button>
        <Button variant='danger' onClick={() => onDelete(cell.row.original)}>
          X
        </Button>
      </Stack>
    );
  };

  const vehicle = [
    {
      Header: "#id",
      accessor: "id",
      Cell: ({ cell }) => <div>{cell.row.values.id}</div>,
    },
    {
      Header: "Numer rejestracyjny",
      accessor: "regNumber",
    },
    {
      Header: "Opis",
      accessor: "desc",
    },
    {
      Header: "Tara",
      accessor: "tare",
    },
    {
      Header: " ",
      accessor: "type",
      style: {
        width: "120px",
      },
      Cell: ({ cell }) => <Actions cell={cell} />,
    },
  ];

  const driver = [
    {
      Header: "#id",
      accessor: "id",
      Cell: ({ cell }) => <div>{cell.row.values.id}</div>,
    },
    {
      Header: "Imię",
      accessor: "name",
    },
    {
      Header: "Nazwisko",
      accessor: "surname",
    },
    {
      Header: " ",
      accessor: "type",
      style: {
        width: "90px",
      },
      Cell: ({ cell }) => <Actions cell={cell} />,
    },
  ];

  const rawMaterial = [
    {
      Header: "#id",
      accessor: "id",
      Cell: ({ cell }) => <div>{cell.row.values.id}</div>,
    },
    {
      Header: "Nazwa skrócona",
      accessor: "shortName",
    },
    {
      Header: "Nazwa pełna",
      accessor: "fullName",
    },
    {
      Header: " ",
      accessor: "type",
      style: {
        width: "90px",
      },
      Cell: ({ cell }) => <Actions cell={cell} />,
    },
  ];

  const contractor = [
    {
      Header: "#id",
      accessor: "id",
      Cell: ({ cell }) => <div>{cell.row.values.id}</div>,
    },
    {
      Header: "Nazwa skrócona",
      accessor: "shortName",
    },
    {
      Header: "Nazwa pełna",
      accessor: "fullName",
    },
    {
      Header: "Typ",
      accessor: "contractorType",
    },
    {
      Header: " ",
      accessor: "type",
      style: {
        width: "90px",
      },
      Cell: ({ cell }) => <Actions cell={cell} />,
    },
  ];

  const updateMyData = (rowIndex, columnId, value) => {
    setSkipPageReset(true);
    setData((old) =>
      old.map((row, index) => {
        if (index === rowIndex) {
          return {
            ...old[rowIndex],
            [columnId]: value,
          };
        }
        return row;
      })
    );
  };

  useEffect(() => {
    setSkipPageReset(false);
  }, [data]);

  if (isLoading) {
    return (
      <Row className='justify-content-md-center mt-5'>
        <Spinner animation='border' role='status'>
          <span className='visually-hidden'>Wczytuje...</span>
        </Spinner>
      </Row>
    );
  }
  if (isError) {
    return <ApiError error={error} />;
  }
  return (
    <Stack gap={3}>
      <Row className='mt-3'>
        <Col>
          <Stack className='me-2' gap={3} direction='horizontal'>
            <Button onClick={() => refetch()}>Odśwież</Button>
            <DropdownButton id='dropdown-item-button' title='Dodaj do słownika'>
              {AuthService.userCan("edytuje_pojazdy") && (
                <Dropdown.Item as='button' onClick={() => setOpenVehicle(true)}>
                  Pojazd
                </Dropdown.Item>
              )}
              {AuthService.userCan("edytuje_kierowcow") && (
                <Dropdown.Item as='button' onClick={() => setOpenDriver(true)}>
                  Kierowce
                </Dropdown.Item>
              )}
              {AuthService.userCan("edytuje_surowce") && (
                <Dropdown.Item as='button' onClick={() => setOpenRaw(true)}>
                  Surowiec
                </Dropdown.Item>
              )}
              {AuthService.userCan("edytuje_kontrahentow") && (
                <Dropdown.Item as='button' onClick={() => setOpenContractor(true)}>
                  Kontrahenta
                </Dropdown.Item>
              )}
            </DropdownButton>
          </Stack>
        </Col>
        <Col>
          <Stack gap={3} direction='horizontal'>
            <div className='text-nowrap'>Wybierz słownik:</div>
            <select
              className='form-control'
              value={dictionaryType}
              onChange={(e) => {
                setDictionaryType(Number(e.target.value));
              }}>
              {["Pojazdy", "Kierowcy", "Surowiec", "Kontrahenci"].map((dictionaryType, index) => (
                <option key={dictionaryType} value={index + 1}>
                  {dictionaryType}
                </option>
              ))}
            </select>
          </Stack>
        </Col>
      </Row>
      <Table columns={column} data={data} updateMyData={updateMyData} skipPageReset={skipPageReset} />
      <Modal.Driver show={isOpenDriver} onHide={() => setOpenDriver(false)} />
      <Modal.Vehicle show={isOpenVehicle} onHide={() => setOpenVehicle(false)} />
      <Modal.Contractor show={isOpenContractor} onHide={() => setOpenContractor(false)} />
      <Modal.Raw show={isOpenRaw} onHide={() => setOpenRaw(false)} />
    </Stack>
  );
};

export default Dictionary;

const EditableCell = ({ value: initialValue, row: { index }, column: { id }, updateMyData }) => {
  const [value, setValue] = useState(initialValue);

  const onChange = (e) => {
    if (typeof value === "number") {
      setValue(parseInt(e.target.value));
    } else {
      setValue(e.target.value);
    }
  };
  const onSelectChange = (value) => {
    setValue(value.map((item) => item.value));
    updateMyData(
      index,
      id,
      value.map((item) => item.value)
    );
  };

  const options = [
    { label: "Odbiorca", value: "recipient" },
    { label: "Przewoźnik", value: "carrier" },
    { label: "Dostawca", value: "supplier" },
  ];

  const onBlur = () => {
    updateMyData(index, id, value);
  };

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  if (typeof value === "object") {
    return <Select defaultValue={initialValue.map((value) => options?.find((option) => option?.value === value))} onChange={onSelectChange} isMulti options={options} />;
  } else {
    return <input className='form-control' type={typeof value === "number" ? "number" : "text"} value={value} onChange={onChange} onBlur={onBlur} />;
  }
};

const defaultColumn = {
  Cell: EditableCell,
};

const Table = ({ columns, data, updateMyData, skipPageReset }) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,

    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      defaultColumn,
      autoResetPage: !skipPageReset,
      updateMyData,
    },
    usePagination
  );

  return (
    <>
      <BTable striped bordered hover size='md' {...getTableProps()}>
        <thead>
          {headerGroups?.map((headerGroup) => (
            <tr {...headerGroup?.getHeaderGroupProps()}>
              {headerGroup?.headers.map((column) => (
                <th {...column?.getHeaderProps([{ style: column.style }])}>{column?.render("Header")}</th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page?.map((row, i) => {
            prepareRow(row);
            return (
              <tr key={i} {...row?.getRowProps()}>
                {row?.cells?.map((cell) => {
                  return <td {...cell?.getCellProps()}>{cell?.render("Cell")}</td>;
                })}
              </tr>
            );
          })}
        </tbody>
      </BTable>

      <ButtonToolbar aria-label='Toolbar with button groups'>
        <Stack direction='horizontal' gap={3}>
          <div className='text-nowrap'>
            <span>
              {"Strona "}
              <strong>
                {pageIndex + 1} of {pageOptions.length}
              </strong>
            </span>
          </div>
          <div className='text-nowrap'>| Idź do strony:</div>
          <div>
            <input
              className='form-control'
              style={{ maxWidth: "80px" }}
              type='number'
              defaultValue={pageIndex + 1}
              onChange={(e) => {
                const page = e.target.value ? Number(e.target.value) - 1 : 0;
                gotoPage(page);
              }}
            />
          </div>
          <div>
            <select
              className='form-control'
              value={pageSize}
              onChange={(e) => {
                setPageSize(Number(e.target.value));
              }}>
              {[10, 20, 30, 40, 50].map((pageSize) => (
                <option key={pageSize} value={pageSize}>
                  Pokaż {pageSize}
                </option>
              ))}
            </select>
          </div>
          <ButtonGroup className='me-2' aria-label='First group'>
            <Button onClick={() => gotoPage(0)}>{"<<"}</Button>
            <Button onClick={() => previousPage()}>{"<"}</Button>
            <Button onClick={() => nextPage()}>{">"}</Button>
            <Button onClick={() => gotoPage(pageCount - 1)}>{">>"}</Button>
          </ButtonGroup>
        </Stack>
      </ButtonToolbar>
    </>
  );
};
