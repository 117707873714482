import moment from "moment";
import "moment/locale/pl";

const handleDateFormat = (date) => {
  moment.locale("pl");
  let _date = new Date(date);
  return moment(_date).format("MM-D HH:mm");
};

export default handleDateFormat;
