import { Popover, Stack } from "react-bootstrap";
import React from "react";
import Icons from "components/Icons";

import handleHistoryText from "handlers/Table/handleHistoryText";

const historyPopover = (history) => {
  return (
    <Popover id='popover-basic' style={{ maxWidth: "400px" }}>
      <Popover.Body>{showHistory(history)}</Popover.Body>
    </Popover>
  );
};

export default historyPopover;

const showHistory = (data) => {
  return data.map((item, index) => {
    if (data.length !== index + 1) {
      return (
        <Stack key={index} gap={2} direction='horizontal'>
          <Icons.Circle />
          {handleHistoryText(item)}
        </Stack>
      );
    } else {
      return (
        <Stack gap={2} key={index} direction='horizontal'>
          <Icons.Circle className='me-2' background='green' />
          {handleHistoryText(item)}
        </Stack>
      );
    }
  });
};
