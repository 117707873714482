/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { Col, Row, Button, Stack } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { Routes, Route, Link, Navigate } from "react-router-dom";

import Icons from "components/Icons";
import Schedule from "components/forms/Schedule";

import { useAddDeliveriesData, useSingleDeliveriesData, useUpdateDeliveryData } from "queries/useDeliveriesData";

import { initialValues } from "components/forms/Schedule/initialValues";

const ScheduleForm = (props) => {
  const [formProps, setFormProps] = useState({
    formVariant: "new",
    initialValues: initialValues,
    onSubmit: (values) => {
      mutate(values);
    },
  });

  const { mutate } = useAddDeliveriesData();
  const { mutate: update } = useUpdateDeliveryData();

  let navigate = useNavigate();
  let { id, form } = useParams();

  const { data, refetch } = useSingleDeliveriesData(`${id}`);

  useEffect(() => {
    if (id !== undefined) {
      refetch();
    }
  }, [id]);

  useEffect(() => {
    switch (form) {
      case "edit":
        return setFormProps({
          formVariant: "edit",
          navigate: navigate,
          initialValues: data,
          onSubmit: (values) => {
            update(values);
          },
          id: id,
        });
      default:
        return setFormProps({
          formVariant: "new",
          navigate: navigate,
          initialValues: initialValues,
          onSubmit: (values) => {
            mutate(values);
          },
        });
    }
  }, [id, data, form]);

  return (
    <Stack gap={4} className="mt-4">
      <Row>
        <Col>
          <Button variant="primary" onClick={() => navigate(-1)}>
            <Icons.Back /> Cofnij
          </Button>
        </Col>
      </Row>
      <Row>
        <Routes>
          <Route path="/*" element={<Navigate to="/" />} />
          <Route path="new" element={<Schedule {...formProps} />} />
          <Route path="edit/:id" element={<Schedule {...formProps} />} />
        </Routes>
      </Row>
    </Stack>
  );
};

export default ScheduleForm;
