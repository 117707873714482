import axios from "axios";
import authHeader from "services/auth-header";
import toast from "react-hot-toast";

const client = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

export const request = ({ ...options }) => {
  client.defaults.headers.common.Authorization = authHeader().Authorization;

  const onSuccess = (response) => response;
  const onError = (error) => {
    if (error.response.status === 401) {
      toast.error(`Błąd autoryzacji, wylogowuje: ${error.message}`);
      setTimeout(() => {
        localStorage.removeItem("user");
        localStorage.removeItem("auth");
        window.location.reload();
      }, 3000);
    }
    return error.response;
  };
  return client(options).then(onSuccess).catch(onError);
};
