import { useQuery, useMutation, useQueryClient } from "react-query";
import { request } from "../utils/axios";
import toast from "react-hot-toast";
import { useContext } from "react";
import { WebsocketContext } from "context/WebsocketContext";

const fetchRoles = () => {
  return request({ url: "/roles", method: "get" });
};

export const useRolesData = () => {
  return useQuery(["roles"], () => fetchRoles(), {
    refetchOnWindowFocus: false,
    onError: (error) => {
      toast.error(`Error on background update: ${error.message}`);
    },
    select: (data) => {
      const Role = data?.data?.data;
      return Role;
    },
  });
};

const addRoleItem = (data) => {
  return request({ url: "/roles", method: "post", data: data });
};

export const useAddRoleData = () => {
  const queryClient = useQueryClient();
  const [ready, send] = useContext(WebsocketContext);

  return useMutation(addRoleItem, {
    onError: (error) => {
      toast.error(`Error on background update: ${error.message}`);
    },
    onSuccess: () => {
      queryClient.invalidateQueries(["roles"]);

      if (ready) {
        send(JSON.stringify({ operation: "invalidate", entity: ["roles"] }));
      }
    },
  });
};

const updateRoleItem = (data) => {
  return request({ url: `roles/${data.id}`, method: "patch", data: data });
};

export const useUpdateRoleData = () => {
  const queryClient = useQueryClient();
  const [ready, send] = useContext(WebsocketContext);

  return useMutation(updateRoleItem, {
    onError: (error) => {
      toast.error(`Error on background update: ${error.message}`);
    },
    onSuccess: () => {
      queryClient.invalidateQueries(["roles"]);

      if (ready) {
        send(JSON.stringify({ operation: "invalidate", entity: ["roles"] }));
      }
    },
  });
};

const deleteRoleItem = (id) => {
  return request({
    url: `roles/${id}`,
    method: "delete",
  });
};

export const useDeleteRoleData = () => {
  const queryClient = useQueryClient();
  const [ready, send] = useContext(WebsocketContext);

  return useMutation(deleteRoleItem, {
    onError: (error) => {
      toast.error(`Error on background update: ${error.message}`);
    },
    onSuccess: () => {
      queryClient.invalidateQueries(["roles"]);

      if (ready) {
        send(JSON.stringify({ operation: "invalidate", entity: ["roles"] }));
      }
    },
  });
};
