import { useQuery, useMutation, useQueryClient } from "react-query";
import { request } from "../utils/axios";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { useContext } from "react";
import { WebsocketContext } from "../context/WebsocketContext";

const revertStatus = (query) => {
  return request({ url: `/history-back?id=${query}`, method: "get" });
};

export const useRevertStatus = () => {
  const queryClient = useQueryClient();
  const [ready, send] = useContext(WebsocketContext);
  return useMutation(revertStatus, {
    retry: false,
    onError: (error) => {
      toast.error(`Error on background update: ${error.message}`);
    },
    onSuccess: () => {
      toast.success("Pomyślnie cofnięto status");
      queryClient.invalidateQueries(["deliveries"]);
      if (ready) {
        send(JSON.stringify({ operation: "invalidate", entity: ["deliveries"] }));
      }
    },
  });
};

const fetchDeliveries = (query) => {
  return request({ url: `/deliveries/${query}`, method: "get" });
};

export const useDeliveriesData = (query) => {
  return useQuery(["deliveries", query], () => fetchDeliveries(query), {
    enabled: query !== undefined,
    refetchOnWindowFocus: false,
    cacheTime: query === undefined ? 0 : 1000 * 60 * 60 * 24,

    onError: (error) => {
      toast.error(`Error on background update: ${error.message}`);
    },
    select: (data) => {
      const deliveries = data?.data?.data;
      return deliveries;
    },
  });
};

export const useSingleDeliveriesData = (query) => {
  return useQuery(["deliveries", query], () => fetchDeliveries(query), {
    enabled: query !== undefined,
    refetchOnWindowFocus: false,
    onError: (error) => {
      toast.error(`Error on background update: ${error.message}`);
    },
    select: (data) => {
      const deliveries = data?.data?.data;
      return deliveries;
    },
  });
};

const deleteDeliveries = (query) => {
  return request({ url: `/deliveries/${query}`, method: "delete" });
};

export const useDeleteSingleDeliveries = () => {
  const queryClient = useQueryClient();
  let navigate = useNavigate();
  const [ready, send] = useContext(WebsocketContext);

  return useMutation(deleteDeliveries, {
    onError: (error) => {
      toast.error(`Error on background update: ${error.message}`);
    },
    onSuccess: () => {
      toast.success("Pomyślnie usunięto wpis");
      queryClient.invalidateQueries(["deliveries"]);
      if (ready) {
        send(JSON.stringify({ operation: "invalidate", entity: ["deliveries"] }));
      }
      setTimeout(() => {
        navigate(-1);
      }, 500);
    },
  });
};

const addDeliveriesItem = (item) => {
  return request({ url: "/deliveries", method: "post", data: item });
};

export const useAddDeliveriesData = () => {
  const queryClient = useQueryClient();
  const [ready, send] = useContext(WebsocketContext);

  let navigate = useNavigate();
  return useMutation(addDeliveriesItem, {
    onError: (error) => {
      toast.error(`Error on background update: ${error.message}`);
    },

    onSuccess: (data, variables) => {
      toast.success("Pomyślnie dodano wpis");
      setTimeout(() => {
        if (variables.containers[0].type === 1 || variables.containers[0].type === 2) {
          navigate("/schedule");
        } else {
          navigate("/delivery");
        }
      }, 500);
      if (ready) {
        send(JSON.stringify({ operation: "invalidate", entity: ["deliveries"] }));
      }
      queryClient.invalidateQueries(["deliveries"]);
    },
  });
};

const updateDeliveryItem = (item) => {
  return request({ url: `/deliveries/${item.id}`, method: "patch", data: item });
};

export const useUpdateDeliveryData = () => {
  const queryClient = useQueryClient();
  let navigate = useNavigate();
  const [ready, send] = useContext(WebsocketContext);

  return useMutation(updateDeliveryItem, {
    onError: (error) => {
      toast.error(`Error on background update: ${error.message}`);
    },

    onSuccess: (data, variables) => {
      toast.success("Pomyślnie edytowano wpis");

      setTimeout(() => {
        if (variables.containers[0].type === 1 || variables.containers[0].type === 2) {
          navigate("/schedule");
        } else {
          navigate("/delivery");
        }
      }, 500);
      if (ready) {
        send(JSON.stringify({ operation: "invalidate", entity: ["deliveries"] }));
      }

      queryClient.invalidateQueries(["deliveries"], variables.id);
    },
  });
};
