/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { Col, Row, Button, Stack } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { Routes, Route, Navigate } from "react-router-dom";

import Icons from "components/Icons";
import Delivery from "components/forms/Delivery/Create";
import EditDelivery from "components/forms/Delivery/Edit";
import StepForm from "components/stepform";

import { useAddDeliveriesData, useSingleDeliveriesData, useUpdateDeliveryData } from "queries/useDeliveriesData";
import { initialValuesNew } from "components/forms/Delivery/Create/initialValues";

const DeliveryForm = (props) => {
  let navigate = useNavigate();
  let { id, form } = useParams();

  const [formProps, setFormProps] = useState();

  const { mutate } = useAddDeliveriesData();
  const { mutate: update } = useUpdateDeliveryData();
  const { data, isLoading, error } = useSingleDeliveriesData(id);

  useEffect(() => {
    switch (form) {
      case "schedule":
        return setFormProps({
          formVariant: "schedule",
          isLoading,
          initialValues: data,
          onSubmit: (values) => {
            update(values);
          },
        });

      case "edit":
        return setFormProps({
          formVariant: "edit",
          isLoading,
          initialValues: data,
          onSubmit: (values) => {
            update(values);
          },
        });

      case "redirect":
        return setFormProps({
          onSubmit: (values) => {
            mutate(values);
          },
        });

      default:
        return setFormProps({
          formVariant: "new",
          isLoading: false,
          initialValues: initialValuesNew,
          onSubmit: (values) => {
            mutate(values);
          },
        });
    }
  }, [data, form, id, isLoading]);

  return (
    <Stack gap={4} className='mt-4'>
      <Row>
        <Col>
          <Button variant='primary' onClick={() => navigate(-1)}>
            <Icons.Back /> Cofnij
          </Button>
        </Col>
      </Row>
      <Row>
        <Routes>
          <Route path='/*' element={<Navigate to='/' />} />
          <Route path='schedule/:id' element={<Delivery {...formProps} />} />
          <Route path='edit/:id' element={<EditDelivery {...formProps} />} />
          <Route path='new' element={<Delivery {...formProps} />} />
          <Route path='redirect' element={<StepForm {...formProps} />} />
        </Routes>
      </Row>
    </Stack>
  );
};

export default DeliveryForm;
