/* eslint-disable no-unused-vars */
import React from "react";
import { Stack, Row, Col, InputGroup } from "react-bootstrap";

import { FieldArray } from "formik";

import FormField from "components/Inputs/FormField";

const JdeOrder = (props) => {
  const { values, isEditable, rawMaterialDictionaryData, supplierDictionaryData } = props;

  return (
    <Stack gap={4}>
      <FieldArray name='containers'>
        {({ remove, push }) => (
          <Stack gap={3}>
            {values?.containers?.length > 0 &&
              values?.containers.map((container, containerIndex) => {
                const containerNumber = containerIndex + 1;
                return (
                  <Stack gap={3} key={containerIndex} className={containerIndex > 0 ? "mt-2" : ""}>
                    {container.suppliers.length > 0 &&
                      container.suppliers.map((supplier, index) => (
                        <Stack gap={3} key={index}>
                          <Row key={index}>
                            <Col>
                              <h4>
                                Kontener #{containerNumber}: {supplier.weight}kg (
                                {container?.rawMaterial ? JSON.parse(rawMaterialDictionaryData.find((obj) => obj.id === container.rawMaterial).body).shortName : ""}) od{" "}
                                {supplier?.name ? JSON.parse(supplierDictionaryData.find((obj) => obj.id === supplier.name).body).shortName : ""}
                              </h4>
                            </Col>
                          </Row>
                          <Row>
                            <FormField isDisabled={!isEditable} as={Col} sm='8' label='Numer zlecenia JDE' type='text' name={`containers.${containerIndex}.suppliers.${index}.jdeOrderNumber`} />
                            <FormField isDisabled={!isEditable} as={Col} sm='4' label='Typ zlecenia' type='text' name={`containers.${containerIndex}.suppliers.${index}.jdeOrderType`} />
                          </Row>
                          <Row>
                            <FormField isDisabled={!isEditable} as={Col} sm='8' label='Numer zlecenia OS' type='text' name={`containers.${containerIndex}.suppliers.${index}.osOrderNumber`} />
                            <FormField
                              isDisabled={!isEditable}
                              as={Col}
                              sm='4'
                              label='Ilość kilometrów'
                              type='numer'
                              Append={<InputGroup.Text id='inputGroupPrepend'>km</InputGroup.Text>}
                              name={`containers.${containerIndex}.suppliers.${index}.osKm`}
                            />
                          </Row>
                        </Stack>
                      ))}
                  </Stack>
                );
              })}
          </Stack>
        )}
      </FieldArray>
    </Stack>
  );
};

export default JdeOrder;

JdeOrder.defaultProps = {
  isEditable: true,
};
