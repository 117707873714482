import React from "react";

import * as yup from "yup";

import FormField from "components/Inputs/FormField";
import ModalWrapper from "../Template/";
import { useAddDictionaryData } from "queries/useDictionaryData";

const Raw = (props) => {
  const { onHide } = props;

  const { mutate } = useAddDictionaryData();

  const initialValues = {
    shortName: "",
    fullName: "",
  };

  const schema = yup.object({
    shortName: yup.string().required("Pole wymagane"),
    fullName: yup.string().required("Pole wymagane"),
  });

  const onSubmit = (values) => {
    mutate({ type: 3, body: JSON.stringify(values) });
  };

  return (
    <ModalWrapper {...props} schema={schema} initial={initialValues} onSubmit={(values) => onSubmit(values)} title='Surowiec ' onHide={onHide}>
      <FormField required label='Nazwa skrócona' type='text' name='shortName' />
      <FormField required label='Nazwa pełna' type='text' name='fullName' />
    </ModalWrapper>
  );
};

export default Raw;
