import React, { useState, useEffect } from "react";
import BTable from "react-bootstrap/Table";
import { useTable } from "react-table";
import { Button, Stack, Badge, OverlayTrigger, Tooltip } from "react-bootstrap";
import { Link } from "react-router-dom";
import Icons from "components/Icons";
import DuplicateModal from "components/Modal/Duplicate";
import handleWeightCount from "handlers/Table/handleWeightCount";
import moment from "moment";
import "moment/locale/pl";
import AuthService from "services/auth.service";

const Schedule = ({ data, refetch }) => {
  const [isDuplicateOpen, setOpenDuplicate] = useState(false);
  const [rowData, setRowData] = useState();
  const [weightSum, setWeightSum] = useState(0);
  moment.locale("pl");

  const handleModalOpen = (data) => {
    setRowData(data);
    setOpenDuplicate(true);
  };

  const handleDateFormat = (date) => {
    let _date = new Date(date);
    return moment(_date).format("dddd, D MMMM , HH:mm");
  };

  const handleDateDiff = (data) => {
    const planned = moment(data.plannedDateAndTime);
    const done = moment(data.containers[0].state_change_history?.at(-1)?.created_at);

    const duration = moment.duration(done.diff(planned));
    const days = Math.abs(duration.days());
    const hours = Math.abs(duration.hours());
    const minutes = Math.abs(duration.minutes());
    const roundMinTotal = Math.round(duration.asMinutes());
    const minTotal = Math.abs(roundMinTotal);

    const naming = (value, type) => {
      switch (true) {
        case value === 1 && type === "d":
          return <>{value} dzień </>;
        case value > 1 && type === "d":
          return <>{value} dni </>;
        case value === 1 && type === "h":
          return <>{value} godzina </>;
        case value > 1 && type === "h":
          return <>{value} godzin </>;
        case value === 1 && type === "min":
          return <>{value} minuta </>;
        case value > 1 && type === "min":
          return <>{value} minuty </>;
        default:
          return null;
      }
    };

    const durationSwitch = (value) => {
      switch (true) {
        case value < 60 && value > 0:
          return <>{naming(minutes, "min")}</>;
        case value < 1440 && value >= 60:
          return <>{naming(hours, "h")}</>;
        case value >= 1440:
          return <>{naming(days, "d")}</>;
        default:
          return null;
      }
    };

    if (duration.asMinutes() > 0) {
      return (
        <p>
          Różnica: {durationSwitch(minTotal)}
          później
        </p>
      );
    } else {
      return (
        <p>
          Różnica: {durationSwitch(minTotal)}
          wcześniej
        </p>
      );
    }
  };

  const timeCheck = (data) => {
    if (data === undefined || Array.isArray(data)) {
      return;
    }
    const firstDates = data[0];
    const lastDates = data[data.length - 1];

    const isBetween = (data) => {
      const date = moment(data.date);
      const createAt = moment(data.created_at);
      const today = moment().set({ hour: "7", minute: "00", second: "00" });
      const tomorrow = moment().add(1, "days").set({ hour: "6", minute: "59", second: "00" });

      return date.isSameOrAfter(today) && date.isSameOrBefore(tomorrow) && createAt.isSameOrAfter(today) && createAt.isSameOrBefore(tomorrow);
    };

    if (data.length === 1) {
      return isBetween(firstDates);
    } else {
      return isBetween(lastDates);
    }
  };

  const getTrProps = (row) => {
    const data = row.original.planned_date_and_time_change_history;

    if (timeCheck(data) && row.original.containers[0].type !== 2) {
      return {
        style: {
          background: "#fff58c",
        },
      };
    }
  };

  useEffect(() => {
    if (data !== undefined) {
      let _weightSum = 0;
      for (let i = 0; i <= data?.length - 1; i++) {
        setWeightSum((_weightSum += handleWeightCount(data[i])));
      }
    }
  }, [data]);

  const columns = React.useMemo(
    () => [
      {
        className: "edit-row align-middle",
        Header: () => <div></div>,
        accessor: "id",
        Cell: ({ cell }) => (
          <div className="text-center">
            {cell.row.original.containers[0].type === 2 ? (
              <Stack direction="horizontal">
                {AuthService.userCan("duplikacja_planu_dostaw") && (
                  <Button size="sm" onClick={() => handleModalOpen(cell.row.original)} variant="link">
                    <Icons.Duplicate />
                  </Button>
                )}
              </Stack>
            ) : (
              <Stack direction="horizontal">
                {AuthService.userCan("edycja_planu_dostaw") && (
                  <Link className="btn btn-sm btn-link" to={`edit/${cell.row.values.id}`}>
                    <Icons.Edit />
                  </Link>
                )}
                {AuthService.userCan("duplikacja_planu_dostaw") && (
                  <Button size="sm" onClick={() => handleModalOpen(cell.row.original)} variant="link">
                    <Icons.Duplicate />
                  </Button>
                )}
              </Stack>
            )}
          </div>
        ),
      },
      {
        Header: () => (
          <div>
            Planowana <br />
            data i godzina
          </div>
        ),
        accessor: "tableData.plannedDateAndTime",
        Cell: ({ cell }) => {
          const historyDate = cell.row.original.planned_date_and_time_change_history;

          if (cell.row.original.containers[0].type === 2) {
            return <p>{handleDateFormat(cell.row.values["tableData.plannedDateAndTime"])}</p>;
          } else
            return (
              <Stack>
                <p style={{ textDecoration: "line-through" }}>{historyDate.length > 1 && handleDateFormat(historyDate[historyDate.length - 2].date)}</p>
                <p className={timeCheck(historyDate) ? "blink" : ""}>{handleDateFormat(cell.row.values["tableData.plannedDateAndTime"])}</p>
              </Stack>
            );
        },
      },
      {
        Header: "Przewoźnik",
        accessor: "tableData.carrier",
      },
      {
        Header: "Szczegóły dostawy",
        accessor: "deliveryDetails",
        Cell: ({ cell }) => {
          return (
            <>
              {cell.row.original?.containers?.map((container, index) => {
                return (
                  <div key={index}>
                    <p>
                      Kontener # {index + 1} ({container.rawMaterialString !== "" && JSON.parse(container.rawMaterialString)?.shortName})
                    </p>
                    {container.suppliers.map((item, index) => {
                      return (
                        <p key={index}>
                          <span>
                            {item.weight} KG od {item.nameString !== "" && JSON.parse(item.nameString)?.shortName}
                          </span>
                          <Badge bg="warning" style={{ marginLeft: "10px" }} text="dark">
                            {item.snc}
                          </Badge>
                        </p>
                      );
                    })}
                  </div>
                );
              })}
            </>
          );
        },
      },
      {
        className: "kg-row align-middle",
        Footer: weightSum / 1000 + "t",
        accessor: "tableData.weight",
        Header: () => (
          <div>
            Ilość razem <br />
            [kg]
          </div>
        ),
        Cell: ({ cell }) => {
          const weight = cell.row.original?.containers?.map((container) => {
            let total = 0;
            container.suppliers.forEach((item) => {
              total += item.weight;
            });
            return total;
          });
          const sumWeight = weight.reduce((a, b) => a + b, 0);
          return <>{sumWeight}</>;
        },
      },
      {
        style: {
          width: "250px",
        },
        className: "button-row align-middle",
        Header: () => <div className="text-center">Status</div>,
        accessor: "status",
        Cell: ({ cell }) => (
          <div className="text-center">
            {cell.row.original.containers[0].type === 2 ? (
              <>
                <p>&#10004; ZREALIZOWANA</p>
                <OverlayTrigger placement="top" overlay={<Tooltip id="tooltip-status">{handleDateDiff(cell.row.original)}</Tooltip>}>
                  <p>
                    {handleDateFormat(cell.row.original.containers[0].state_change_history.sort((a, b) => {
                      return new Date(b.created_at) - new Date(a.created_at)
                    })[0].created_at)}
                  </p>
                </OverlayTrigger>
              </>
            ) : (
              <>
                {AuthService.userCan("zmien_status_realizuj_dostawe") && (
                  <Link to={`/delivery/create/schedule/${cell.row.values.id}`}>
                    <Button variant="primary">REALIZUJ DOSTAWĘ</Button>
                  </Link>
                )}
              </>
            )}
          </div>
        ),
      },
    ],
    [data, weightSum]
  );

  return (
    <div>
      <Table columns={columns} data={data} getTrProps={(row) => getTrProps(row)} />
      <DuplicateModal show={isDuplicateOpen} data={rowData} onHide={() => setOpenDuplicate(false)} refetch={refetch} title="test" />
    </div>
  );
};

export default Schedule;

function Table({ columns, data, getTrProps = (props) => props }) {
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow, footerGroups } = useTable({
    columns,
    data,
  });

  return (
    <>
      <BTable striped bordered hover size="md" {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup, i) => (
            <tr key={i} {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column, i) => (
                <th key={i} {...column.getHeaderProps([{ style: column.style }])}>
                  {column.render("Header")}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row, i) => {
            prepareRow(row);
            return (
              <tr key={i} {...row.getRowProps()} {...getTrProps(row)}>
                {row.cells.map((cell, i) => {
                  return (
                    <td
                      className="align-middle"
                      key={i}
                      {...cell.getCellProps({
                        className: cell.column.className,
                      })}>
                      {cell.render("Cell")}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
        <tfoot>
          {footerGroups.map((group, i) => (
            <tr key={i} {...group.getFooterGroupProps()}>
              {group.headers.map((column, i) => (
                <td key={i} {...column.getFooterProps()}>
                  {column.render("Footer")}
                </td>
              ))}
            </tr>
          ))}
        </tfoot>
      </BTable>
    </>
  );
}
