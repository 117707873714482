import React, { useState } from "react";
import { Button, Stack, Row, Col } from "react-bootstrap";
import { FieldArray } from "formik";
import DatePickerField from "components/Inputs/DataTime";
import FormSelect from "components/Inputs/FormSelect";
import * as Modal from "components/Modal";

const Redirect = (props) => {
  const { handlePrevStep, isValid, isSubmitting, values } = props;
  const [isOpenContractor, setOpenContractor] = useState(false);

  return (
    <>
      <Stack gap={4}>
        <FieldArray name="containers">
          {() => (
            <Row>
              {values?.containers?.length > 0 &&
                values?.containers.map((container, index) => (
                  <Stack gap={3} key={index}>
                    <Row>
                      <Col>
                        <h3>Kontener #{index + 1}</h3>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm="8">
                        <FormSelect label="Odbiorca" onListButtonClick={() => setOpenContractor(true)} dictionary="recipient" name={`containers.${index}.redirectRecipient`} />
                      </Col>
                    </Row>
                    <Row>
                      <DatePickerField sm="8" as={Col} required label="Data dostawy" name={`containers.${index}.redirectDeliveryDate`} />
                    </Row>
                  </Stack>
                ))}
            </Row>
          )}
        </FieldArray>
        <Row>
          <Stack direction="horizontal" gap={3}>
            <Button variant="primary" type="submit" disabled={!isValid || isSubmitting}>
              Zapisz
            </Button>
            <Button variant="danger" onClick={() => handlePrevStep(values)}>
              Cofnij
            </Button>
          </Stack>
        </Row>
      </Stack>
      <Modal.Contractor show={isOpenContractor} onHide={() => setOpenContractor(false)} />
    </>
  );
};
export default Redirect;
