import styled from "styled-components";

export const ErrorWrapper = styled.div`
  height: 100%;
  min-height: 100px
  width: 100%;
  background-color: black;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 3em;
`;
