/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-key */
import React, { useState, useEffect } from "react";
import { useTable, usePagination } from "react-table";
import BTable from "react-bootstrap/Table";
import { Stack, Button, Row, Col, ButtonToolbar, ButtonGroup, DropdownButton, Dropdown, Spinner } from "react-bootstrap";
import * as Modal from "components/Modal";
import ApiError from "components/error";
import { useUsersData, useUpdateUserData, useDeleteUserData } from "queries/useUsersData";
import { useRolesData } from "../../queries/useUsersRolesData";

const Users = () => {
  const [data, setData] = useState([]);
  const [skipPageReset, setSkipPageReset] = useState(false);
  const [isOpenUser, setOpenUser] = useState(false);

  const { mutate: update } = useUpdateUserData();
  const { mutate: _delete } = useDeleteUserData();
  const { data: _data, isLoading, refetch, isRefetching, isError, error } = useUsersData();
  const { data: roles } = useRolesData();

  const onSubmit = (values) => {
    update(values);
  };

  const onDelete = (values) => {
    _delete(values);
  };

  useEffect(() => {
    if (_data !== undefined) {
      setData(_data);
    }
  }, [_data, isRefetching]);

  const Actions = ({ cell }) => (
    <Stack direction='horizontal' gap={3}>
      <Button onClick={() => onSubmit(cell.row.original)}>Zapisz</Button>
      <Button variant='danger' onClick={() => onDelete(cell.row.original.id)}>
        X
      </Button>
    </Stack>
  );

  const columns = [
    {
      Header: "#id",
      accessor: "id",
      Cell: ({ cell }) => <div>{cell.row.values.id}</div>,
    },
    {
      Header: "Imię",
      accessor: "name",
    },
    {
      Header: "Email",
      accessor: "email",
    },
    {
      Header: "Hasło",
      accessor: "password",
    },
    {
      Header: "Role",
      accessor: "role",
      Cell: ({ cell }) => {
        return (
          <>
            <select
              className='form-control'
              type='text'
              value={cell.row.values.role}
              onChange={(e) => {
                updateMyData(cell.row.index, cell.column.id, e.target.value);
              }}>
              <option value=''> </option>
              {roles &&
                roles.length &&
                roles.map((role) => (
                  <option key={role.name} value={role.id}>
                    {role.name}
                  </option>
                ))}
            </select>
          </>
        );
      },
    },
    {
      Header: "Akcje",
      style: {
        width: "120px",
      },
      Cell: ({ cell }) => <Actions cell={cell} />,
    },
  ];

  const updateMyData = (rowIndex, columnId, value) => {
    setSkipPageReset(true);
    setData((old) =>
      old.map((row, index) => {
        if (index === rowIndex) {
          return {
            ...old[rowIndex],
            [columnId]: value,
          };
        }
        return row;
      })
    );
  };

  useEffect(() => {
    setSkipPageReset(false);
  }, [data]);

  if (isLoading) {
    return (
      <Row className='justify-content-md-center mt-5'>
        <Spinner animation='border' role='status'>
          <span className='visually-hidden'>Wczytuje...</span>
        </Spinner>
      </Row>
    );
  }
  if (isError) {
    return <ApiError error={error} />;
  }
  return (
    <Stack gap={3}>
      <Row className='mt-3'>
        <Col>
          <Stack className='me-2' gap={3} direction='horizontal'>
            <Button onClick={() => refetch()}>Odśwież</Button>
            <Button onClick={() => setOpenUser(true)}>Dodaj użytkownika</Button>
          </Stack>
        </Col>
      </Row>
      <Table columns={columns} data={data} updateMyData={updateMyData} skipPageReset={skipPageReset} />
      <Modal.User show={isOpenUser} onHide={() => setOpenUser(false)} />
    </Stack>
  );
};

export default Users;

const EditableCell = ({ value: initialValue, row: { index }, column: { id }, updateMyData }) => {
  const [value, setValue] = useState(initialValue);

  const onChange = (e) => {
    e.preventDefault();
    setValue(e.target.value);
    updateMyData(index, id, e.target.value);
  };

  const onBlur = (e) => {
    e.preventDefault();
    setValue(e.target.value);
    updateMyData(index, id, e.target.value);
  };

  useEffect(() => {
    if (initialValue === null || initialValue === undefined) {
      setValue("");
    } else {
      setValue(initialValue);
    }
  }, [initialValue]);

  return <input className='form-control' type='text' value={value} onChange={onChange} />;
};

const defaultColumn = {
  Cell: EditableCell,
};

const Table = ({ columns, data, updateMyData, skipPageReset }) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      defaultColumn,
      autoResetPage: !skipPageReset,
      updateMyData,
    },
    usePagination
  );

  return (
    <>
      <BTable striped bordered hover size='md' {...getTableProps()}>
        <thead>
          {headerGroups?.map((headerGroup) => (
            <tr {...headerGroup?.getHeaderGroupProps()}>
              {headerGroup?.headers.map((column) => (
                <th {...column?.getHeaderProps([{ style: column.style }])}>{column?.render("Header")}</th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page?.map((row, i) => {
            prepareRow(row);
            return (
              <tr key={i} {...row?.getRowProps()}>
                {row?.cells?.map((cell) => {
                  return <td {...cell?.getCellProps()}>{cell?.render("Cell")}</td>;
                })}
              </tr>
            );
          })}
        </tbody>
      </BTable>

      <ButtonToolbar aria-label='Toolbar with button groups'>
        <Stack direction='horizontal' gap={3}>
          <div className='text-nowrap'>
            <span>
              {"Strona "}
              <strong>
                {pageIndex + 1} of {pageOptions.length}
              </strong>
            </span>
          </div>
          <div className='text-nowrap'>| Idź do strony:</div>
          <div>
            <input
              className='form-control'
              style={{ maxWidth: "80px" }}
              type='number'
              defaultValue={pageIndex + 1}
              onChange={(e) => {
                const page = e.target.value ? Number(e.target.value) - 1 : 0;
                gotoPage(page);
              }}
            />
          </div>
          <div>
            <select
              className='form-control'
              value={pageSize}
              onChange={(e) => {
                setPageSize(Number(e.target.value));
              }}>
              {[10, 20, 30, 40, 50].map((pageSize) => (
                <option key={pageSize} value={pageSize}>
                  Pokaż {pageSize}
                </option>
              ))}
            </select>
          </div>
          <ButtonGroup className='me-2' aria-label='First group'>
            <Button onClick={() => gotoPage(0)}>{"<<"}</Button>
            <Button onClick={() => previousPage()}>{"<"}</Button>
            <Button onClick={() => nextPage()}>{">"}</Button>
            <Button onClick={() => gotoPage(pageCount - 1)}>{">>"}</Button>
          </ButtonGroup>
        </Stack>
      </ButtonToolbar>
    </>
  );
};
