/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

export const HDIComments = ({ rowData }) => {
  const [copyText, setCopyText] = React.useState("");

  const hdiCommentsPerContainer = rowData?.map((container) => container.suppliers.map((supplier) => supplier.hdiComments));

  const deleteDuplicatesCommentsPerContainer = hdiCommentsPerContainer?.map((container) => [...new Set(container.filter((item) => item != null))]);

  if (deleteDuplicatesCommentsPerContainer.every((container) => container.length === 0)) return null;

  const handleCopy = (comment) => {
    navigator.clipboard.writeText(comment);
    setCopyText(comment);
  };

  return (
    <div className={deleteDuplicatesCommentsPerContainer.length > 1 ? "cellWrapper" : ""}>
      {deleteDuplicatesCommentsPerContainer.map((container, index) => {
        return (
          <div className='subcell' key={index} style={{ textAlign: "left", alignItems: "start", gap: "0" }}>
            {container.map((comment, index) => {
              if (!comment) {
                return null;
              }
              return (
                <OverlayTrigger
                  key={index}
                  placement='top'
                  onExited={() => setCopyText("")}
                  overlay={
                    <Tooltip id='tooltip-top'>
                      <p style={{ margin: "0" }}>{comment}</p>
                      {copyText === comment ? <p style={{ color: "green", margin: "0" }}> Skopiowano!</p> : <p style={{ margin: "0" }}> (Kliknij, aby skopiować)</p>}
                    </Tooltip>
                  }>
                  <p style={{ whiteSpace: "nowrap", color: "#BB2D3B", cursor: "pointer" }} onClick={() => handleCopy(comment)}>
                    {comment.length > 15 ? comment.slice(0, 15) + "..." : comment}
                  </p>
                </OverlayTrigger>
              );
            })}
          </div>
        );
      })}
    </div>
  );
};
