/* eslint-disable no-unused-vars */
import React, { useRef, useCallback, useState, useEffect } from "react";
import debounce from "lodash.debounce";
import { Formik, Form, FieldArray } from "formik";
import { Col, InputGroup, Row, Button, Stack, Tabs, Tab, Modal as ModalB } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import AuthService from "services/auth.service";

import * as Modal from "components/Modal";

import FormDebug from "components/debug/FormDebug";

import { containers, suppliers } from "./initialValues";
import { validationSchema } from "./validationSchema";

import DeliveryStep from "../../DeliveryStep";
import HDIControls from "../../HDIControls";
import JdeOrder from "../../JdeOrder/";

import { useDeleteSingleDeliveries } from "queries/useDeliveriesData";
import { useDictionariesData } from "queries/useDictionaryData";

const EditDelivery = (props) => {
  const { initialValues, onSubmit, isLoading } = props;
  const [show, setShow] = useState(false);
  const [isOpenDriver, setOpenDriver] = useState(false);
  const [isOpenVehicle, setOpenVehicle] = useState(false);
  const [isOpenRaw, setOpenRaw] = useState(false);
  const [isOpenContractor, setOpenContractor] = useState(false);
  const [isEditable, setEditable] = useState({ hdi: false, delivery: false });
  const { data: rawMaterialDictionaryData } = useDictionariesData(3);
  const { data: supplierDictionaryData } = useDictionariesData(4);

  const { mutate } = useDeleteSingleDeliveries();

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleDelete = () => {
    mutate(initialValues?.id);
    setShow(false);
  };

  let navigate = useNavigate();

  const [key, setKey] = useState(window.location.hash.replace("#", "") || "delivery");

  const formikRef = useRef();

  useEffect(() => {
    if (AuthService.userCan("edycja_rejestr_dostaw")) {
      setEditable({ hdi: true, delivery: true });
    } else if (AuthService.userCan("edycja_rejestr_dostaw_hdi")) {
      setEditable({ hdi: true, delivery: false });
    } else {
      setEditable({ hdi: false, delivery: false });
    }
  }, [AuthService]);

  if (isLoading) return <div>Loading...</div>;

  return (
    <Stack gap={2}>
      <Row>
        <Formik initialValues={initialValues || {}} innerRef={formikRef} enableReinitialize validationSchema={validationSchema} onSubmit={(values) => onSubmit(values)}>
          {({ values, isValid, isSubmitting, errors }) => (
            <Form>
              <Tabs defaultActiveKey={key} id='edit-delivery' className='mb-3'>
                <Tab eventKey='delivery' title='Dostawa'>
                  <DeliveryStep
                    isEditable={isEditable.delivery}
                    setOpenContractor={setOpenContractor}
                    setOpenDriver={setOpenDriver}
                    setOpenVehicle={setOpenVehicle}
                    setOpenRaw={setOpenRaw}
                    values={values}
                    isValid={isValid}
                    isSubmitting={isSubmitting}
                  />
                </Tab>
                <Tab eventKey='jde' title='Zlecenie'>
                  <JdeOrder values={values} isEditable={isEditable.delivery} rawMaterialDictionaryData={rawMaterialDictionaryData} supplierDictionaryData={supplierDictionaryData} />
                </Tab>
                <Tab eventKey='hdi' title='Kontrola HDI'>
                  <HDIControls
                    isEditable={isEditable.hdi || isEditable.delivery}
                    edit={true}
                    formikRef={formikRef}
                    values={values}
                    isValid={isValid}
                    isSubmitting={isSubmitting}
                    rawMaterialDictionaryData={rawMaterialDictionaryData}
                    supplierDictionaryData={supplierDictionaryData}
                  />
                </Tab>
              </Tabs>
              <Stack direction='horizontal' className='mt-2' gap={2}>
                {(isEditable?.delivery === true || isEditable?.hdi === true) && (
                  <Button variant='primary' type='submit' disabled={!isValid || isSubmitting}>
                    Zapisz
                  </Button>
                )}
                <Button variant='warning' onClick={() => navigate(-1)}>
                  Anuluj
                </Button>
                {AuthService.userCan("usun_rejest_dostawy") && (
                  <Button variant='danger' onClick={() => handleShow()}>
                    Usuń rejestr dostawy
                  </Button>
                )}
              </Stack>
              <Row>
                <Col xl='2' lg='0'>
                  <FormDebug values={values} />
                </Col>
              </Row>
            </Form>
          )}
        </Formik>
      </Row>
      <ModalB className='delete-modal' show={show} onHide={handleClose}>
        <ModalB.Header closeButton>
          <ModalB.Title>Czy usunąć rejestr dostawy?</ModalB.Title>
        </ModalB.Header>
        <ModalB.Footer>
          <Button variant='primary' onClick={handleClose}>
            Anuluj
          </Button>
          <Button variant='danger' onClick={handleDelete}>
            Potwierdź
          </Button>
        </ModalB.Footer>
      </ModalB>
      <Modal.Driver show={isOpenDriver} onHide={() => setOpenDriver(false)} />
      <Modal.Vehicle show={isOpenVehicle} onHide={() => setOpenVehicle(false)} />
      <Modal.Contractor show={isOpenContractor} onHide={() => setOpenContractor(false)} />
      <Modal.Raw show={isOpenRaw} onHide={() => setOpenRaw(false)} />
    </Stack>
  );
};

export default EditDelivery;
