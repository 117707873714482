import React from "react";
import { FieldArray } from "formik";
import { Col, InputGroup, Row, Button, Stack } from "react-bootstrap";

import FormSelect from "components/Inputs/FormSelect";
import FormField from "components/Inputs/FormField";

const Suppliers = ({ container, name, setOpen, suppliers, isEditable }) => {
  return (
    <>
      <FieldArray name={name}>
        {({ remove, push }) => (
          <>
            <Row>
              <h5 className="required">Dostawcy</h5>
            </Row>
            <Stack gap={3}>
              {container.suppliers?.length > 0 &&
                container.suppliers.map((supplier, index) => (
                  <Row key={index}>
                    <Col sm="5">
                      <FormSelect
                        isDisabled={!isEditable}
                        name={`${name}.${index}.name`}
                        onListButtonClick={setOpen}
                        dictionary="supplier"
                      />
                    </Col>
                    <FormField
                      isDisabled={!isEditable}
                      as={Col}
                      type="number"
                      name={`${name}.${index}.weight`}
                      Append={<InputGroup.Text id="inputGroupPrepend">KG</InputGroup.Text>}
                    />
                    <FormField
                      isDisabled={!isEditable}
                      as={Col}
                      type="text"
                      autocomplete="off"
                      name={`${name}.${index}.snc`}
                      Prepend={<InputGroup.Text id="inputGroupPrepend">SCN</InputGroup.Text>}
                    />
                    <Col
                      sm="1"
                      className="mt-1">
                      {container.suppliers.length > 1 && (
                        <Button
                          variant="danger"
                          className=""
                          onClick={() => remove(index)}>
                          X
                        </Button>
                      )}
                    </Col>
                  </Row>
                ))}
              {container.suppliers?.length > 0 && (
                <Row style={{ marginTop: "-10px" }}>
                  <Col sm="5"></Col>
                  <Col>Razem: {container.suppliers.reduce((total, supplier) => total + supplier.weight, 0)}kg</Col>
                </Row>
              )}
              <Row>
                <Col>
                  {isEditable && (
                    <Button
                      variant="primary"
                      onClick={() => push(suppliers)}>
                      Dodaj dostawcę
                    </Button>
                  )}
                </Col>
              </Row>
            </Stack>
          </>
        )}
      </FieldArray>
    </>
  );
};

export default Suppliers;

Suppliers.defaultProps = {
  isEditable: true,
};
