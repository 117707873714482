import axios from "axios";
const API_URL = process.env.REACT_APP_API_URL;

class AuthService {
  login(email, password) {
    return axios
      .post(API_URL + "/auth/login", {
        email,
        password,
      })
      .then((response) => {
        if (response.data.access_token) {
          localStorage.setItem("user", JSON.stringify({ token: response.data.access_token, name: response.data.me.name }));
          localStorage.setItem("auth", JSON.stringify({ auth: response.data.me.authorizations }));
        }
        return response.data;
      });
  }
  logout() {
    localStorage.removeItem("user");
    localStorage.removeItem("auth");
  }
  getCurrentUser() {
    return JSON.parse(localStorage.getItem("user"));
  }

  userCan(method) {
    let auth = JSON.parse(localStorage.getItem("auth")).auth;
    let authObj = JSON.parse(auth);
    return authObj.includes(method);
  }
}
export default new AuthService();
