import React from "react";
import { InputGroup } from "react-bootstrap";
import * as yup from "yup";

import FormField from "components/Inputs/FormField";
import ModalWrapper from "../Template/";
import { useAddDictionaryData } from "queries/useDictionaryData";

const Vehicle = (props) => {
  const { onHide } = props;

  const { mutate } = useAddDictionaryData();

  const initialValues = {
    regNumber: "",
    desc: "",
    tare: 0,
  };

  const schema = yup.object({
    regNumber: yup.string().required("Pole wymagane"),
  });

  const onSubmit = (values) => {
    mutate({ type: 1, body: JSON.stringify(values) });
  };

  return (
    <ModalWrapper {...props} schema={schema} initial={initialValues} onSubmit={(values) => onSubmit(values)} title='Rejestracja pojazdu' onHide={onHide}>
      <FormField required label='Numer rejestracyjny' type='text' name='regNumber' />
      <FormField label='Opis' type='text' name='desc' />
      <FormField label='Tara' type='number' name='tare' Append={<InputGroup.Text id='inputGroupPrepend'>KG</InputGroup.Text>} />
    </ModalWrapper>
  );
};

export default Vehicle;
