import React from "react";
import { Row, Col, Stack } from "react-bootstrap";

import Icons from "components/Icons";
import { Circle, Text, Line } from "./style";

const FormSteps = ({ formStep }) => {
  const checkClass = (i) => {
    if (formStep === i) {
      return "active";
    } else if (formStep > i) {
      return "done";
    }
    return "idle";
  };

  return (
    <Row>
      <Col sm={4}>
        <Stack direction='horizontal' gap={3}>
          <Circle className={checkClass(1)}>{formStep === 1 ? "1" : <Icons.Check />}</Circle>
          <Text>Dostawa</Text>
          {formStep >= 2 ? <Line /> : ""}
        </Stack>
      </Col>
      <Col sm={4}>
        <Stack direction='horizontal' gap={3}>
          <Circle className={checkClass(2)}>{formStep <= 2 ? "2" : <Icons.Check />}</Circle>
          <Text>Kontrola HDI</Text>
          {formStep === 3 ? <Line /> : ""}
        </Stack>
      </Col>
      <Col sm={4}>
        <Stack direction='horizontal' gap={3}>
          <Circle className={checkClass(3)}>3</Circle>
          <Text>Przekierowanie</Text>
        </Stack>
      </Col>
    </Row>
  );
};

export default FormSteps;
