import React, { createContext, useContext, useState } from "react";

const ScheduleContext = createContext();

const useSchedule = () => {
  return useContext(ScheduleContext);
}

const useScheduleProvider = () => {
  const [topFilters, setTopFilters] = useState({
    day: null,
    week: 0,
    snc: "",
  });
  const [query, setQuery] = useState("?type[]=1&type[]=2&week=0");
  const [weekNumber, setWeekNumber] = useState();

  return {
    query,
    setQuery,
    weekNumber,
    setWeekNumber,
    topFilters,
    setTopFilters
  };
}

function ScheduleProvider({ children }) {
  const schedule = useScheduleProvider();
  return (
    <ScheduleContext.Provider value={schedule}>
      {children}
    </ScheduleContext.Provider>
  );
}

export {
  ScheduleProvider,
  useSchedule
}
