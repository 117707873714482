import { useQueryClient } from "react-query";
import { createContext, useEffect, useRef, useState } from "react";
import toast from "react-hot-toast";

export const WebsocketContext = createContext(false, null, () => {});

export const WebsocketProvider = ({ children }) => {
  const [isReady, setIsReady] = useState(false);

  const queryClient = useQueryClient();
  const ws = useRef(null);
  const websocketUrl = process.env.REACT_APP_WS_URL || "";

  useEffect(() => {
    const protocol = window.location.protocol === "https:" ? "wss" : "ws";
    const url = websocketUrl.split("://")[1];

    const socket = new WebSocket(`${protocol}://${url}/`);

    socket.onopen = () => {
      setIsReady(true);
    };
    socket.onclose = () => {
      setIsReady(false);
    };

    socket.onerror = (error) => {
      console.error("SonacWS", error);
      toast.error("Błąd połączenia z serwerem synchronizacji");
    };

    socket.onmessage = (event) => {
      const data = JSON.parse(event.data);
      switch (data.operation) {
        case "invalidate":
          setTimeout(
            () =>
              queryClient.invalidateQueries(...data.entity, {
                refetchInactive: true,
                refetchActive: true,
              }),
            1000
          );
          toast("Pomyślnie synchronizowano dane");
          break;
        case "update":
          queryClient.setQueriesData(data.entity, (oldData) => {
            const update = (entity) => (entity.id === data.id ? { ...entity, ...data.payload } : entity);
            return Array.isArray(oldData) ? oldData.map(update) : update(oldData);
          });
          break;
      }
    };

    ws.current = socket;

    return () => {
      if (socket.readyState === WebSocket.OPEN) {
        socket.close();
      }
    };
  }, []);

  const ret = [isReady, ws.current?.send.bind(ws.current)];

  return <WebsocketContext.Provider value={ret}>{children}</WebsocketContext.Provider>;
};
