import React from "react";
import { Modal, Button } from "react-bootstrap";

import Icons from "components/Icons/";
import { useRevertStatus } from "queries/useDeliveriesData";

const RevertStatus = (props) => {
  const { onHide, revertId, ...restProps } = props;

  const { mutate } = useRevertStatus();

  const handleSubmit = () => {
    mutate(revertId);
    onHide();
  };

  return (
    <Modal {...restProps} onHide={onHide} aria-labelledby='contained-modal-title-vcenter' centered>
      <Modal.Header closeButton>
        <Modal.Title className='d-flex align-middle' id='contained-modal-title-vcenter'>
          <Icons.Warning />
          <span className='modal-title'> Czy na pewno wycofać operacje?</span>
        </Modal.Title>
      </Modal.Header>
      <Modal.Footer>
        <Button variant='primary' onClick={() => handleSubmit()}>
          Wycofaj operację
        </Button>
        <Button onClick={onHide}>Anuluj</Button>
      </Modal.Footer>
    </Modal>
  );
};

export default RevertStatus;

RevertStatus.defaultProps = {
  onSubmit: () => alert("brak on submit"),
};
