import React from "react";
import { BrowserRouter as Router, Routes, Route, NavLink, Navigate } from "react-router-dom";
import { Container, Button } from "react-bootstrap";

import Logo from "./Logo";
import Notifications from "components/Notifications";
import * as Page from "pages";
import AuthService from "services/auth.service";
import { DeliveryProvider } from "context/DeliveryContext";
import { ScheduleProvider } from "context/ScheduleContext";

const Navigation = () => {
  const user = AuthService.getCurrentUser();

  if (user && user.token) {
    return (
      <DeliveryProvider>
        <ScheduleProvider>
          <div className='main-container'>
            <Notifications />
            <Router basename={"app"}>
              <div className='nav-wrapper'>
                <Logo />
                <nav>
                  <ul>
                    {AuthService.userCan("widzi_rejestr_dostaw") && (
                      <li>
                        <NavLink to='/delivery'>Rejestr dostaw surowca</NavLink>
                      </li>
                    )}
                    {AuthService.userCan("widzi_plan_dostaw") && (
                      <li>
                        <NavLink to={"/schedule"}>Plan dostaw surowca</NavLink>
                      </li>
                    )}
                    {AuthService.userCan("widzi_menu_slowniki") && (
                      <li>
                        <NavLink to={"/dictionary"}>Słowniki</NavLink>
                      </li>
                    )}
                    {AuthService.userCan("widzi_menu_uzytkownicy") && (
                      <li>
                        <NavLink to={"/users"}>Użytkownicy</NavLink>
                      </li>
                    )}
                    {AuthService.userCan("widzi_menu_role") && (
                      <li>
                        <NavLink to={"/roles"}>Role</NavLink>
                      </li>
                    )}
                    <li>
                      <Button
                        variant='link'
                        onClick={() => {
                          AuthService.logout();
                          window.location.reload(false);
                        }}>
                        Wyloguj
                      </Button>
                    </li>
                  </ul>
                </nav>
              </div>
              <Container fluid>
                <Routes>
                  {/* Delivery */}
                  <Route path='/*' element={<Navigate to='/delivery' />} />
                  <Route path='/delivery/*' element={<Page.Delivery />}>
                    <Route path=':form' element={<Page.Delivery />} />
                  </Route>
                  <Route path='/delivery/create/*' element={<Page.DeliveryForm />}>
                    <Route path=':form' element={<Page.DeliveryForm />} />
                    <Route path=':form/:id' element={<Page.DeliveryForm />} />
                  </Route>

                  {/* Schedule */}
                  <Route path='/schedule/' element={<Page.Schedule />} />
                  <Route path='/schedule/*' element={<Page.ScheduleForm />}>
                    <Route path=':form' element={<Page.ScheduleForm />} />
                    <Route path=':form/:id' element={<Page.ScheduleForm />} />
                  </Route>
                  {/* Dictionary */}
                  <Route path='/dictionary' element={<Page.Dictionary />} />
                  {/* Admin */}
                  <Route path='/users' element={<Page.Users />} />
                  <Route path='/roles' element={<Page.Roles />} />
                </Routes>
              </Container>
            </Router>
          </div>
        </ScheduleProvider>
      </DeliveryProvider>
    );
  } else {
    return (
      <>
        <Notifications />
        <Router basename={"app"}>
          <Routes>
            <Route path='/*' element={<Navigate to='/login' />} />
            <Route path='/login' element={<Page.Login />} />
          </Routes>
        </Router>
      </>
    );
  }
};

export default Navigation;
