/* eslint-disable indent */

import React, { useState } from "react";
import { Button, Stack, Badge, OverlayTrigger, ButtonGroup, Tooltip } from "react-bootstrap";
import { Link } from "react-router-dom";

import historyPopover from "components/HistoryPopover";
import * as Modal from "components/Modal";
import Table from "components/Table";
import Icons from "components/Icons";
import { HDIComments } from "components/Table/HDIComments";

import { HDIBadge } from "components/Table/HDIBadge";

import moment from "moment";

import AuthService from "services/auth.service";

import { acceptanceTypeStatus } from "models/acceptanceTypeStatus";

import handleStatus from "handlers/Table/handleTableStatus";
import { handlContainerWeightCount } from "handlers/Table/handleWeightCount";

import handleDateFormat from "handlers/handleDateFormat";
import { useDelivery } from "context/DeliveryContext";

const DeliveryActive = ({ data, deliveriesWeight, ...rest }) => {
  const [revertId, setRevertId] = useState();
  const [statusData, setStatusData] = useState();
  const [_data, setData] = useState();
  const [changeTimeOpen, setChangeTimeOpen] = useState(false);
  const [revertStatusOpen, setRevertStatusOpen] = useState(false);
  const [acceptanceOpen, setAcceptanceOpen] = useState(false);
  const [squareOpen, setSquareOpen] = useState(false);
  const [productionOpen, setProductionOpen] = useState(false);
  const [redirectOpen, setRedirectOpen] = useState(false);
  const { status } = useDelivery();

  const handleRevertModal = (id) => {
    setRevertId(id);
    setRevertStatusOpen(true);
  };

  const handleChangeTimeModal = (data) => {
    setStatusData(data);
    setChangeTimeOpen(true);
  };

  const handleStatusChange = ({ data, status }) => {
    setData({ data: data, status: status });
    handleModalChange(status);
  };

  const handleModalChange = (status) => {
    switch (status) {
      case 1:
        return setAcceptanceOpen(true);
      case 2:
        return setAcceptanceOpen(true);
      case 3:
        return setAcceptanceOpen(true);
      case 7:
        return setAcceptanceOpen(true);
      case 4:
        return setProductionOpen(true);
      case 5:
        return setSquareOpen(true);
      case 6:
        return setRedirectOpen(true);
      default:
        break;
    }
  };

  const columns = React.useMemo(
    () => [
      {
        className: "edit-row align-middle",
        id: "id",
        Header: "",
        accessor: "id",
        Cell: ({ cell }) => (
          <Stack className='text-center justify-content-center'>
            <Link className='btn btn-sm btn-link' to={`/delivery/create/edit/${cell.row.values.id}`}>
              <Icons.Edit />
            </Link>
            <HDIBadge row={cell.row} />
            <p>#{cell.row.values.id}</p>
          </Stack>
        ),
      },
      {
        style: {
          width: "120px",
        },
        id: "createdAt",
        Header: "Terminy",
        accessor: "createdAt",
        Cell: ({ cell }) => {
          return (
            <Stack gap={1} className='text-center justify-content-center'>
              {cell.row.original?.createdAt && (
                <OverlayTrigger key='created_at' placement='top' overlay={<Tooltip id='tooltip-created_at'>Rejestracja: {handleDateFormat(cell.row.original?.createdAt)}</Tooltip>}>
                  <p>R:{handleDateFormat(cell.row.original?.createdAt)}</p>
                </OverlayTrigger>
              )}
              {cell.row.original?.plannedDateAndTime && (
                <OverlayTrigger
                  key='created_at2'
                  placement='top'
                  overlay={<Tooltip id='tooltip-created_at'>Planowana data i godzina: {handleDateFormat(cell.row.original?.plannedDateAndTime)}</Tooltip>}>
                  <p>P:{handleDateFormat(cell.row.original?.plannedDateAndTime)}</p>
                </OverlayTrigger>
              )}
            </Stack>
          );
        },
      },
      {
        id: "carrier",
        Header: "Przewoźnik",
        accessor: "carrier",
        Cell: ({ cell }) => {
          return (
            <Stack gap={1} className='justify-content-center'>
              <p>{cell.row.original.tableData.carrier}</p>
              <p>{cell.row.original.tableData.driver}</p>
            </Stack>
          );
        },
      },
      {
        id: "rawMaterial",
        Header: "Surowiec",
        accessor: "containers",
        Cell: ({ cell }) => {
          return (
            <>
              <ContainerMap
                cellData={cell.row.original}
                component={(index, container) => {
                  if (!status.includes(container.type.toString())) {
                    return;
                  }
                  return (
                    <div className='subcell' key={index}>
                      {container?.rawMaterialString !== "" && JSON.parse(container.rawMaterialString)?.shortName}
                    </div>
                  );
                }}
              />
            </>
          );
        },
      },
      {
        id: "supplier",
        Header: "Dostawca",
        accessor: "containers",
        Cell: ({ cell }) => {
          return (
            <div className={cell.row.original?.containers > 1 ? "cellWrapper" : ""}>
              {cell.row.original?.containers?.map((container, index) => {
                if (!status.includes(container.type.toString())) {
                  return;
                }
                return (
                  <div className='subcell' key={index}>
                    {container?.suppliers?.length > 1
                      ? "Zbiorczy"
                      : container?.suppliers.map((item, index) => {
                          return <p key={index}>{item?.nameString !== "" && JSON.parse(item.nameString)?.shortName}</p>;
                        })}
                    <p>{container?.vehicleString !== "" && JSON.parse(container.vehicleString)?.regNumber}</p>
                  </div>
                );
              })}
            </div>
          );
        },
      },
      {
        id: "containerNumber",
        Header: "Kontener",
        accessor: "containers",
        Cell: ({ cell }) => {
          return (
            <>
              <ContainerMap
                cellData={cell.row.original}
                component={(index, container) => {
                  if (!status.includes(container.type.toString())) {
                    return;
                  }
                  return (
                    <div className='subcell' key={index}>
                      {container?.containerNumber}
                    </div>
                  );
                }}
              />
            </>
          );
        },
      },
      {
        style: {
          width: "140px",
          textAlign: "right",
        },
        id: "weight",
        accessor: "containers",
        Footer: () => <div className='weightSum'> {deliveriesWeight ? deliveriesWeight + "t" : ""}</div>,
        Header: () => <>Ilość razem [kg]</>,
        Cell: ({ cell }) => {
          return (
            <>
              {cell.value.map((container, index) => {
                if (!status.includes(container.type.toString())) {
                  return;
                }
                return (
                  <div className='subcell' gap={1} key={index}>
                    {handlContainerWeightCount(container)}
                  </div>
                );
              })}
            </>
          );
        },
      },
      {
        style: {
          width: "70px",
          textAlign: "center",
        },
        id: "SNC",
        Header: "SCN",
        accessor: "containers",
        Cell: ({ cell }) => {
          return (
            <>
              {cell.row.original?.containers?.map((container, index) => {
                if (!status.includes(container.type.toString())) {
                  return;
                }
                return (
                  <div className='subcell' gap={1} key={index}>
                    {container.suppliers.length > 1 ? (
                      <Badge key={index} bg='warning' text='dark'>
                        SCN zbiorczy
                      </Badge>
                    ) : (
                      <Badge bg='warning' text='dark'>
                        {container.suppliers[0]?.snc}
                      </Badge>
                    )}
                  </div>
                );
              })}
            </>
          );
        },
      },
      {
        style: {
          width: "130px",
          textAlign: "center",
        },
        id: "hdiComments",
        Header: "HDI Kom.",
        accessor: "containers",
        Cell: ({ cell }) => {
          return <HDIComments rowData={cell?.value} />;
        },
      },
      {
        style: {
          width: "380px",
          textAlign: "center",
        },
        id: "lastOperation",
        Header: "Ostatnie operacje",
        accessor: "containers",
        Cell: ({ cell }) => {
          return (
            <>
              {cell.value.map((container, index) => {
                if (!status.includes(container.type.toString())) {
                  return;
                }
                return (
                  <div className='subcell' gap={1} key={index}>
                    <Stack direction='horizontal' gap={1}>
                      {container?.state_change_history?.length > 1 && (
                        <ButtonGroup>
                          {AuthService.userCan("cofnij_status_rejestr") && (
                            <Button size='sm' variant='link' onClick={() => handleChangeTimeModal(container)}>
                              <Icons.Edit />
                            </Button>
                          )}
                          {AuthService.userCan("cofnij_status_rejestr") && (
                            <Button size='sm' variant='link' onClick={() => handleRevertModal(container.id)}>
                              <Icons.Revert />
                            </Button>
                          )}
                        </ButtonGroup>
                      )}
                      <Stack>
                        <OverlayTrigger placement='top' overlay={renderTooltip(container?.state_change_history?.at(-1)?.created_at, cell.row.values.createdAt)}>
                          <div>{container?.state_change_history?.length > 1 && <LastStatus cellData={container} />}</div>
                        </OverlayTrigger>
                        {container?.state_change_history?.length > 1 && (
                          <OverlayTrigger trigger={["hover", "focus"]} placement='top' overlay={historyPopover(container?.state_change_history.slice(0, -1))}>
                            <Button size='sm' variant='link'>
                              (pokaż historię operacji)
                            </Button>
                          </OverlayTrigger>
                        )}
                      </Stack>
                    </Stack>
                  </div>
                );
              })}
            </>
          );
        },
      },
      {
        style: {
          textAlign: "center",
        },
        className: "button-row align-middle",
        id: "status",
        Header: "Status",
        accessor: "containers",
        Cell: ({ cell }) => {
          return (
            <>
              {cell.value.map((container, index) => {
                if (!status.includes(container.type.toString())) {
                  return;
                }
                return (
                  <div className='subcell' gap={1} key={index}>
                    <Stack gap={2} direction='horizontal' className='justify-content-center'>
                      {handleStatus({ data: container, handleStatusChange })}
                    </Stack>
                  </div>
                );
              })}
            </>
          );
        },
      },
    ],
    [data]
  );

  return (
    <>
      <Table columns={columns} data={data} {...rest} />
      <Modal.RevertStatus revertId={revertId} show={revertStatusOpen} onHide={() => setRevertStatusOpen(false)} />
      <Modal.Acceptance data={_data} show={acceptanceOpen} onHide={() => setAcceptanceOpen(false)} />
      <Modal.UnloadingSquare data={_data} show={squareOpen} onHide={() => setSquareOpen(false)} />
      <Modal.UnloadingProduction data={_data} show={productionOpen} onHide={() => setProductionOpen(false)} />
      <Modal.Removal data={_data} show={redirectOpen} onHide={() => setRedirectOpen(false)} />
      <Modal.ChangeStatusTime statusdata={statusData} show={changeTimeOpen} onHide={() => setChangeTimeOpen(false)} />
    </>
  );
};

export default DeliveryActive;

const ContainerMap = ({ cellData, component }) => {
  return cellData.containers?.map((container, index) => {
    return component(index, container);
  });
};

const renderTooltip = (regTime, entryTime) => {
  const entry = moment(entryTime);
  const reg = moment(regTime);

  const durationM = Math.abs(moment.duration(reg.diff(entry)).minutes());
  const durationH = Math.abs(moment.duration(reg.diff(entry)).hours());
  const durationD = Math.abs(moment.duration(reg.diff(entry)).days());

  const naming = (value, type) => {
    switch (true) {
      case value === 1 && type === "d":
        return <>{value} dzień</>;
      case value > 1 && type === "d":
        return <>{value} dni</>;
      case value === 1 && type === "h":
        return <>{value} godzina</>;
      case value > 1 && type === "h":
        return <>{value} godziny</>;
      case value === 1 && type === "m":
        return <>{value} minuta</>;
      case value > 1 && type === "m":
        return <>{value} minuty</>;
      default:
        return <>{value} minut</>;
    }
  };

  return (
    <Tooltip id='button-tooltip'>
      Czas oczekiwania {durationD > 0 && naming(durationD, "d")} {durationH > 0 && naming(durationH, "h")} {durationM >= 0 && naming(durationM, "m")}
    </Tooltip>
  );
};

const LastStatus = ({ cellData }) => {
  const { state_to, acceptance_type_from, acceptance_type_to, created_at } = cellData.state_change_history[0];

  switch (true) {
    case state_to === 3 && acceptance_type_to === null:
      return <></>;

    case state_to === 4 && acceptance_type_to === acceptanceTypeStatus.ADMISSION_FIRST:
      return <p>Przyjęcie: {handleDateFormat(created_at)}</p>;

    case state_to === 4 && acceptance_type_to === acceptanceTypeStatus.ENTRY_FIRST:
      return <p>Wjazd: {handleDateFormat(created_at)}</p>;

    case state_to === 4 && acceptance_type_to === acceptanceTypeStatus.NOT_DISCHARGED && acceptance_type_from === acceptanceTypeStatus.ADMISSION_FIRST:
      return <p>Wjazd: {handleDateFormat(created_at)}</p>;

    case state_to === 4 && acceptance_type_to === acceptanceTypeStatus.NOT_DISCHARGED && acceptance_type_from === acceptanceTypeStatus.ENTRY_FIRST:
      return <p>Przyjęcie: {handleDateFormat(created_at)}</p>;

    case state_to === 4 && acceptance_type_to === acceptanceTypeStatus.ACCEPTANCE_PRODUCTION:
      return <p>Przyjęcie do produkcji: {handleDateFormat(created_at)}</p>;

    case state_to === 5 && acceptance_type_to === acceptanceTypeStatus.UNLOADING_YARD:
      return (
        <>
          <p>Miejsce: {cellData?.place}</p>
          <p>Rozładunek na placu: {handleDateFormat(created_at)}</p>
        </>
      );

    case state_to === 6 && acceptance_type_to === acceptanceTypeStatus.UNLOADING_PRODUCTION:
      return <p>Rozładunek na produkcji: {handleDateFormat(created_at)}</p>;

    case state_to === 7 && acceptance_type_to === acceptanceTypeStatus.PLANT_EXPORT:
      return (
        <>
          <p>Wywóz z zakładu: {handleDateFormat(created_at)}</p>
          <p>Odbiorca: {cellData?.removalRecipientString !== "" && JSON.parse(cellData.removalRecipientString)?.shortName}</p>
          <p>Uwagi: {cellData?.removalComments}</p>
        </>
      );
    case state_to === 8:
      return (
        <>
          <p>Przekierowany: {handleDateFormat(created_at)}</p>
          <ContainerMap
            cellData={cellData}
            component={(index, container) => {
              return (
                <Stack key={index}>
                  <p>Odbiorca: {container?.redirectRecipientString !== "" && JSON.parse(container.redirectRecipientString)?.shortName}</p>
                  <p>Data dostawy: {handleDateFormat(container.redirectDeliveryDate)}</p>
                </Stack>
              );
            }}
          />
        </>
      );
    default:
      return <></>;
  }
};
