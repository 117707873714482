/* eslint-disable indent */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { Formik, Form } from "formik";
import { Modal, Button, Badge, Stack } from "react-bootstrap";
import * as yup from "yup";

import FormField from "components/Inputs/FormField";
import Icons from "components/Icons/";
import FormDebug from "components/debug/FormDebug";

import { useUpdateContainerData } from "queries/useContainerData";

const UnloadingSquare = (props) => {
  const [initialValues, setInitialValues] = useState({
    id: null,
    type: 4,
    place: "",
    acceptanceType: 1,
  });
  const { onHide, data } = props;

  const { mutate } = useUpdateContainerData();

  useEffect(() => {
    if (data?.data !== undefined) {
      setInitialValues({
        id: data?.data?.id,
        type: 5,
        place: "",
        acceptanceType: data?.status,
      });
    }
  }, [data]);

  const schema = yup.object({
    place: yup
      .string()
      .required("Pole wymagane")
      .min(2, "Za mało znaków")
      .max(4, "Za dużo znaków")
      .matches(/^[a-zA-Z]\d+$/, "Pierwsza litera, następnie cyfry"),
  });

  return (
    <Modal {...props} onHide={onHide} aria-labelledby='contained-modal-title-vcenter' centered>
      <Modal.Header closeButton>
        <Modal.Title className='d-flex align-middle' id='contained-modal-title-vcenter'>
          <Icons.Warning /> <span className='modal-title'>Zarejestrować rozładunek surowca na plac?</span>
        </Modal.Title>
      </Modal.Header>
      <Formik
        initialValues={initialValues}
        validationSchema={schema}
        enableReinitialize
        onSubmit={(values, { setSubmitting }) => {
          mutate(values);
          setTimeout(() => {
            onHide();
          }, 500);
        }}>
        {({ values, isValid, isSubmitting, errors }) => (
          <Form>
            <Modal.Body>
              <Stack>
                <div>
                  <p>
                    Kontener # {data?.data.containerNumber}: ({data?.data?.rawMaterialString !== undefined ? JSON.parse(data?.data.rawMaterialString).shortName : ""})
                  </p>
                  <ul>
                    {data?.data?.suppliers?.map((supplier, index) => {
                      return (
                        <li key={index}>
                          {supplier.weight}kg od {supplier?.nameString !== undefined ? JSON.parse(supplier.nameString).shortName : ""}
                          {"  "}
                          <Badge bg='warning' text='dark'>
                            {supplier.snc}
                          </Badge>
                        </li>
                      );
                    })}
                  </ul>
                </div>
                <FormField required label='Miejsce' type='text' name='place' autocomplete='off' />
                <FormDebug values={values} />
              </Stack>
            </Modal.Body>
            <Modal.Footer>
              <Button type='submit' disabled={!isValid || isSubmitting}>
                Zapisz
              </Button>
              <Button onClick={onHide}>Anuluj</Button>
            </Modal.Footer>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

export default UnloadingSquare;
