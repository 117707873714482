import React, { useEffect } from "react";
import moment from "moment";

const WeekInput = ({ label, reset, weekNumber, setWeekNumber }) => {
  useEffect(() => {
    setWeekNumber(weekNumber);
  }, [weekNumber]);

  useEffect(() => {
    if (reset === 0) {
      setWeekNumber(Number(moment().format("W")));
    }
  }, [reset]);

  return (
    <>
      <label htmlFor='week-number' className='form-label'>
        {label}
      </label>
      <div className='input-group mb-3'>
        <button type='button' className='input-group-text' onClick={() => setWeekNumber(weekNumber - 1)}>
          {"<"}
        </button>
        {weekNumber && (
          <div className='form-control form-control-lg' id='week-number'>
            Tydzień {weekNumber} ({moment().week(weekNumber).startOf("week").format("D MMMM")} - {moment().week(weekNumber).endOf("week").format("D MMMM")})
          </div>
        )}
        <button type='button' className='input-group-text' onClick={() => setWeekNumber(weekNumber + 1)}>
          {">"}
        </button>
      </div>
    </>
  );
};

export default WeekInput;
