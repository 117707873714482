/* eslint-disable no-unused-vars */
import React, { useState, useRef, useEffect } from "react";
import { Formik, Form } from "formik";
import { Col, Modal, Button, Spinner, Row } from "react-bootstrap";
import * as yup from "yup";

import FormField from "components/Inputs/FormField";
import DatePickerField from "components/Inputs/DataTime";
import Icons from "components/Icons/";
import { useAddDeliveriesData } from "queries/useDeliveriesData";
import FormDebug from "components/debug/FormDebug";

const DuplicateModal = (props) => {
  const { onHide, data: modalData, refetch } = props;

  const { mutate } = useAddDeliveriesData();

  const [_data, setData] = useState();

  const schema = yup.object({
    plannedDateAndTime: yup.date().required("Pole wymagane"),
  });

  const containersEdit = (data) => {
    return data?.containers?.map((container) => ({
      ...container,
      state_change_history: null,
      type: 1,
      weighingProtocol: "",
      containerNumber: "",
      suppliers: container.suppliers.map((supplier) => ({
        ...supplier,
        id: null,
        snc: "",
      })),
    }));
  };

  useEffect(() => {
    if (modalData !== undefined) {
      delete modalData.tableData;
      setData({
        ...modalData,
        type: 1,
        containers: containersEdit(modalData),
      });
    }
  }, [modalData]);

  return (
    <Modal {...props} size='lg' onHide={onHide} aria-labelledby='contained-modal-title-vcenter' centered>
      <Modal.Header closeButton>
        <Modal.Title className='d-flex align-middle' id='contained-modal-title-vcenter'>
          <Icons.Warning /> Czy na pewno skopiować plan dostawy?
        </Modal.Title>
      </Modal.Header>

      <Formik
        initialValues={_data}
        validationSchema={schema}
        enableReinitialize
        onSubmit={(values, { setSubmitting }) => {
          mutate(values, {
            onSuccess: () => {
              refetch();
            },
          });
          setTimeout(() => {
            onHide();
          }, 500);
        }}>
        {({ values, isValid, isSubmitting, errors }) => (
          <Form>
            <Modal.Body>
              <DatePickerField as={Col} required label='Planowana data i czas' name='plannedDateAndTime' />
              <FormDebug values={values} />
            </Modal.Body>
            <Modal.Footer>
              <Button type='submit' disabled={!isValid || isSubmitting}>
                Zapisz
              </Button>
              <Button onClick={onHide}>Anuluj</Button>
            </Modal.Footer>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

export default DuplicateModal;
