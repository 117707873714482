import React from "react";
import toast from "react-hot-toast";
import { QueryClient, QueryClientProvider, QueryCache } from "react-query";
import { WebsocketProvider } from "./context/WebsocketContext";

import TopNavbar from "./components/TopNavbar";
import Navigation from "./components/Navigation";

import "./assets/scss/Main.scss";

// Dev
import { ReactQueryDevtools } from "react-query/devtools";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: Infinity,
    },
  },
  queryCache: new QueryCache({
    onError: (error, query) => {
      if (query.state.data !== undefined) {
        toast.error(`Error on background update: ${error.message}`);
      }
    },
  }),
});

const EnvCheck = () => {
  if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
    return <ReactQueryDevtools />;
  } else {
    return null;
  }
};

const App = () => {
  return (
    <>
      <QueryClientProvider client={queryClient}>
        <WebsocketProvider>
          <TopNavbar />
          <Navigation />
          <EnvCheck />
        </WebsocketProvider>
      </QueryClientProvider>
    </>
  );
};

export default App;
