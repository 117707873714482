import React, { useEffect, useState } from "react";

import * as yup from "yup";

import FormField from "components/Inputs/FormField";
import FormSelect from "components/Inputs/FormSelect";
import ModalWrapper from "../Template/";
import { useAddDictionaryData } from "queries/useDictionaryData";

const Contractor = (props) => {
  const { onHide, type, preselect } = props;
  const [title, setTitle] = useState();

  const { mutate } = useAddDictionaryData();

  const initialValues = {
    shortName: "",
    fullName: "",
    contractorType: type !== undefined ? [type] : [preselect],
  };

  useEffect(() => {
    switch (type) {
      case "carrier":
        setTitle("Rejestracja przewoźnika");
        break;
      case "recipient":
        setTitle("Rejestracja odbiorcy");
        break;
      case "supplier":
        setTitle("Rejestracja dostawcy");
        break;
      default:
        setTitle("Rejestracja kontrahenta");
        break;
    }
  }, [type]);

  const schema = yup.object({
    shortName: yup.string().required("Pole wymagane"),
    fullName: yup.string().required("Pole wymagane"),
  });

  const options = [
    { label: "Odbiorca", value: "recipient" },
    { label: "Przewoźnik", value: "carrier" },
    { label: "Dostawca", value: "supplier" },
  ];

  const onSubmit = (values) => {
    mutate({ type: 4, body: JSON.stringify(values) });
  };

  return (
    <ModalWrapper {...props} schema={schema} initial={initialValues} onSubmit={(values) => onSubmit(values)} title={title} onHide={onHide}>
      <FormField required label='Nazwa skrócona' type='text' name='shortName' />
      <FormField required label='Nazwa pełna' type='text' name='fullName' />
      {!type && <FormSelect label='Typ kontrahenta' options={options} defaultValue={preselect} isMulti={true} name='contractorType' />}
    </ModalWrapper>
  );
};

export default Contractor;
