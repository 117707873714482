/* eslint-disable react/react-in-jsx-scope */

const Icons = {
  Plus: () => {
    return (
      <svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' className='bi' fill='none' viewBox='0 0 24 24' stroke='currentColor' strokeWidth={2}>
        <path strokeLinecap='round' strokeLinejoin='round' d='M12 6v6m0 0v6m0-6h6m-6 0H6' />
      </svg>
    );
  },

  Back: () => {
    return (
      <svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' className='bi' fill='none' viewBox='0 0 24 24' stroke='currentColor' strokeWidth={2}>
        <path strokeLinecap='round' strokeLinejoin='round' d='M7 16l-4-4m0 0l4-4m-4 4h18' />
      </svg>
    );
  },
  Edit: () => {
    return (
      <svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' className='bi' fill='none' viewBox='0 0 24 24' stroke='currentColor' strokeWidth={2}>
        <path strokeLinecap='round' strokeLinejoin='round' d='M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z' />
      </svg>
    );
  },
  Duplicate: () => {
    return (
      <svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' className='bi' fill='none' viewBox='0 0 24 24' stroke='currentColor' strokeWidth={2}>
        <path
          strokeLinecap='round'
          strokeLinejoin='round'
          d='M8 7v8a2 2 0 002 2h6M8 7V5a2 2 0 012-2h4.586a1 1 0 01.707.293l4.414 4.414a1 1 0 01.293.707V15a2 2 0 01-2 2h-2M8 7H6a2 2 0 00-2 2v10a2 2 0 002 2h8a2 2 0 002-2v-2'
        />
      </svg>
    );
  },
  Warning: () => {
    return (
      <svg xmlns='http://www.w3.org/2000/svg' width='29' height='29' className='bi me-2' fill='yellow' viewBox='0 0 24 24' stroke='currentColor' strokeWidth={2}>
        <path
          strokeLinecap='round'
          strokeLinejoin='round'
          d='M8.228 9c.549-1.165 2.03-2 3.772-2 2.21 0 4 1.343 4 3 0 1.4-1.278 2.575-3.006 2.907-.542.104-.994.54-.994 1.093m0 3h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z'
        />
      </svg>
    );
  },
  Revert: () => {
    return (
      <svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' className='bi' fill='none' viewBox='0 0 24 24' stroke='currentColor' strokeWidth={2}>
        <path strokeLinecap='round' strokeLinejoin='round' d='M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15' />
      </svg>
    );
  },
  Check: () => {
    return (
      <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' className='bi' fill='none' viewBox='0 0 24 24' stroke='currentColor' strokeWidth={2}>
        <path strokeLinecap='round' strokeLinejoin='round' d='M5 13l4 4L19 7' />
      </svg>
    );
  },
  Error: () => {
    return (
      <svg xmlns='http://www.w3.org/2000/svg' width='50' height='50' className='bi' viewBox='0 0 20 20' fill='currentColor'>
        <path fillRule='evenodd' d='M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z' clipRule='evenodd' />
      </svg>
    );
  },
  Circle: ({ background }) => {
    return (
      <svg xmlns='http://www.w3.org/2000/svg' x='0px' y='0px' width='12px' height='12px' viewBox='0 0 31.424 31.425' fill={background || "#000"} xmlSpace='preserve'>
        <g>
          <path
            d='M15.712,3.132c6.937,0,12.581,5.644,12.581,12.58c0,6.938-5.645,12.581-12.581,12.581c-6.937,0-12.58-5.645-12.58-12.581
		C3.132,8.775,8.775,3.132,15.712,3.132 M15.712,0C7.035,0,0,7.034,0,15.712c0,8.679,7.035,15.713,15.712,15.713
		c8.677,0,15.712-7.034,15.712-15.713C31.425,7.034,24.389,0,15.712,0L15.712,0z'
          />
        </g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
      </svg>
    );
  },
  Person: () => {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-person" viewBox="0 0 16 16">
        <path d="M8 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6Zm2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0Zm4 8c0 1-1 1-1 1H3s-1 0-1-1 1-4 6-4 6 3 6 4Zm-1-.004c-.001-.246-.154-.986-.832-1.664C11.516 10.68 10.289 10 8 10c-2.29 0-3.516.68-4.168 1.332-.678.678-.83 1.418-.832 1.664h10Z" />
      </svg>
    );
  },
};

export default Icons;
