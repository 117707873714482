import React from "react";
import Accordion from "react-bootstrap/Accordion";
import { useLocation } from "react-router-dom";

const FormDebug = ({ values, errors }) => {
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const debug = query.get("debug");
  if (
    debug === "true" ||
    !process.env.NODE_ENV ||
    process.env.NODE_ENV === "development"
  ) {
    return (
      <Accordion>
        <Accordion.Item eventKey="0">
          <Accordion.Header>Debug</Accordion.Header>
          <Accordion.Body>
            <pre
              style={{
                fontSize: ".85rem",
                padding: ".25rem .5rem",
                overflowX: "scroll",
              }}
            >
              {JSON.stringify({ ...values }, null, 2)}
            </pre>
          </Accordion.Body>
        </Accordion.Item>
        {errors && (
          <Accordion.Item eventKey="1">
            <Accordion.Header>Errors</Accordion.Header>
            <Accordion.Body>
              <pre
                style={{
                  fontSize: ".85rem",
                  padding: ".25rem .5rem",
                  overflowX: "scroll",
                }}
              >
                {JSON.stringify({ ...errors }, null, 2)}
              </pre>
            </Accordion.Body>
          </Accordion.Item>
        )}
      </Accordion>
    );
  } else {
    return null;
  }
};

export default FormDebug;
