import { useMutation, useQueryClient } from "react-query";
import { request } from "../utils/axios";
import toast from "react-hot-toast";
import { useContext } from "react";
import { WebsocketContext } from "context/WebsocketContext";

const updateStatusTime = (data) => {
  return request({ url: `/container-state-change-history?id=${data.id}`, method: "patch", data: { ...data } });
};

export const useUpdateStatusTime = () => {
  const queryClient = useQueryClient();
  const [ready, send] = useContext(WebsocketContext);

  return useMutation(updateStatusTime, {
    onError: (error) => {
      toast.error(`Error on background update: ${error.message}`);
    },
    onSuccess: () => {
      toast.success("Pomyślnie edytowano czas");
      queryClient.invalidateQueries(["deliveries"]);
      if (ready) {
        send(JSON.stringify({ operation: "invalidate", entity: ["deliveries"] }));
      }
    },
  });
};
