import React, { useRef, useCallback, useEffect } from "react";
import debounce from "lodash.debounce";
import { Routes, Route, Link, Navigate, useParams, useNavigate } from "react-router-dom";
import { Stack, Button, ButtonGroup, DropdownButton, Dropdown, Col, Row, Spinner } from "react-bootstrap";
import { Formik, Form } from "formik";
import * as Page from "pages";
import FormField from "components/Inputs/FormField";
import DateInput from "components/Inputs/DateInput";
import FormSelect from "components/Inputs/FormSelect";
import FormDebug from "components/debug/FormDebug";
import StyledLink from "components/StyledLink";
import ApiError from "components/error";

import { useDeliveriesData } from "queries/useDeliveriesData";
import { useDelivery } from "context/DeliveryContext";

const activeStatus = {
  allActive: "&type[]=3&type[]=4&type[]=5",
  new: "&type[]=3",
  accepted: "&type[]=4",
  onSquare: "&type[]=5",
  allInactive: "&type[]=6&type[]=7&type[]=8",
  discharged: "&type[]=6",
  exported: "&type[]=7",
  redirected: "&type[]=8",
};

const Delivery = () => {
  const { topFilters, setTopFilters, page, setPage, resetPage, query, status, setStatus } = useDelivery();

  const formRef = useRef();
  let { form } = useParams();
  const navigate = useNavigate();

  const { data, isLoading, isError, error } = useDeliveriesData(query);

  const changeHandler = () => {
    resetPage();
    setTopFilters({ ...formRef.current.values, place: "" });
  };

  const handleChangePage = (page) => {
    setPage(page);
  };

  const debouncedChangeHandler = useCallback(debounce(changeHandler, 500), [changeHandler]);

  useEffect(() => {
    if (!form) return;

    if (status) {
      setStatus(status);
      if (status.match(/[345]/)) {
        navigate("/delivery/active", { replace: true });
      } else {
        navigate("/delivery/inactive", { replace: true });
      }
    } else {
      setStatus(form === "active" ? activeStatus.allActive : activeStatus.allInactive);
    }
  }, [form, status]);

  return (
    <section className='registry-delivery-wrapper' style={{ height: "100vh" }}>
      <Stack gap='5' className='pt-5' style={{ height: "100%" }}>
        <Formik initialValues={topFilters} enableReinitialize validateOnChange={false} validateOnBlur={false} innerRef={formRef}>
          {({ values }) => (
            <Form onChange={debouncedChangeHandler} className='container-fluid'>
              <Row>
                <FormField as={Col} label='Identyfikator' validOn={false} name='id' />
                <DateInput callback={debouncedChangeHandler} as={Col} label='Data rejestracji' validOn={false} type='date' name='regDate' />
                <FormSelect callback={debouncedChangeHandler} as={Col} label='Przewoźnik' validOn={false} d name='carrier' dictionary='carrier' />
                <FormSelect callback={debouncedChangeHandler} as={Col} label='Surowiec' validOn={false} name='rawMaterial' dictionary='rawMaterial' />
                <FormSelect callback={debouncedChangeHandler} as={Col} label='Dostawca' validOn={false} name='supplier' dictionary='supplier' />
                <FormField as={Col} label='SCN' autocomplete='off' type='text' validOn={false} name='snc' />
                {status == activeStatus.onSquare && <FormField as={Col} label='Miejsce' validOn={false} type='text' name='place' />}
              </Row>
              <FormDebug onChange={debouncedChangeHandler} values={values || {}} />
            </Form>
          )}
        </Formik>

        <Stack direction='horizontal' gap='3'>
          <Link
            to='active'
            onClick={() => {
              resetPage();
              setStatus(activeStatus.allActive);
            }}>
            <Button variant={form === "active" ? "primary" : "outline-secondary"}>Oczekujące</Button>
          </Link>
          <Link
            to='inactive'
            onClick={() => {
              resetPage();
              setStatus(activeStatus.allInactive);
            }}>
            <Button variant={form === "inactive" ? "primary" : "outline-secondary"}>Rozładowane / wywiezione / przekierowane</Button>
          </Link>
          {form === "active" ? (
            <ButtonGroup className='ms-auto'>
              <Button
                variant={status === activeStatus.allActive ? "primary" : "outline-secondary"}
                onClick={() => {
                  setStatus(activeStatus.allActive);
                  resetPage();
                }}>
                Wszystkie
              </Button>
              <Button
                variant={status === activeStatus.new ? "primary" : "outline-secondary"}
                onClick={() => {
                  setStatus(activeStatus.new);
                  resetPage();
                }}>
                Nowe
              </Button>
              <Button
                variant={status === activeStatus.accepted ? "primary" : "outline-secondary"}
                onClick={() => {
                  setStatus(activeStatus.accepted);
                  resetPage();
                }}>
                Przyjęte
              </Button>
              <Button
                variant={status === activeStatus.onSquare ? "primary" : "outline-secondary"}
                onClick={() => {
                  setStatus(activeStatus.onSquare);
                  resetPage();
                }}>
                Na placu
              </Button>
            </ButtonGroup>
          ) : (
            <ButtonGroup className='ms-auto'>
              <Button
                variant={status === activeStatus.allInactive ? "primary" : "outline-secondary"}
                onClick={() => {
                  setStatus(activeStatus.allInactive);
                  resetPage();
                }}>
                Wszystkie
              </Button>
              <Button
                variant={status === activeStatus.discharged ? "primary" : "outline-secondary"}
                onClick={() => {
                  setStatus(activeStatus.discharged);
                  resetPage();
                }}>
                Rozładowane
              </Button>
              <Button
                variant={status === activeStatus.exported ? "primary" : "outline-secondary"}
                onClick={() => {
                  setStatus(activeStatus.exported);
                  resetPage();
                }}>
                Wywiezione
              </Button>
              <Button
                variant={status === activeStatus.redirected ? "primary" : "outline-secondary"}
                onClick={() => {
                  setStatus(activeStatus.redirected);
                  resetPage();
                }}>
                Przekierowane
              </Button>
            </ButtonGroup>
          )}

          <DropdownButton id='dropdown-basic-button' title='Dodaj'>
            <StyledLink to='create/new'>
              <Dropdown.Item as='button'>Dostawa surowca</Dropdown.Item>
            </StyledLink>
            <StyledLink to='create/redirect'>
              <Dropdown.Item as='button'>Przekierowanie surowca</Dropdown.Item>
            </StyledLink>
          </DropdownButton>
        </Stack>
        <Routes>
          <Route path='/*' element={<Navigate to='active' />} />
          <Route
            path='active'
            element={
              <CheckWrapper isLoading={isLoading} isError={isError} error={error}>
                <Page.DeliveryActive
                  data={data?.tableData || []}
                  deliveriesWeight={data?.deliveriesWeight}
                  pageCount={data?.allPages || 0}
                  loading={isLoading}
                  setPage={handleChangePage}
                  pageNumber={page}
                />
              </CheckWrapper>
            }
          />
          <Route
            path='inactive'
            element={
              <CheckWrapper isLoading={isLoading} isError={isError} error={error}>
                <Page.DeliveryInactive
                  data={data?.tableData || []}
                  deliveriesWeight={data?.deliveriesWeight}
                  pageCount={data?.allPages || 0}
                  loading={isLoading}
                  setPage={handleChangePage}
                  pageNumber={page}
                />
              </CheckWrapper>
            }
          />
        </Routes>
      </Stack>
    </section>
  );
};

export default Delivery;

const CheckWrapper = (props) => {
  const { isLoading, isError, children, error } = props;

  if (isLoading) {
    return (
      <Row className='justify-content-md-center mt-5'>
        <Spinner animation='border' role='status'>
          <span className='visually-hidden'>Wczytuje...</span>
        </Spinner>
      </Row>
    );
  }
  if (isError) {
    return <ApiError error={error} />;
  }

  return <>{children}</>;
};
