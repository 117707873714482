/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import { Button, Stack, Row, Col, Modal } from "react-bootstrap";
import { FieldArray } from "formik";

import FormField from "components/Inputs/FormField";
import DateInput from "components/Inputs/DateInput";
import DatePickerField from "components/Inputs/DataTime";

const HDIControls = (props) => {
  const { handleNextStep, handlePrevStep, values, isValid, isSubmitting, formikRef, edit, isEditable, rawMaterialDictionaryData, supplierDictionaryData } = props;
  const [show, setShow] = useState(false);

  const handleClean = () => {
    if (formikRef.current) {
      values?.containers.map((container, index) => {
        formikRef.current.setFieldValue(`containers.${index}.hdiNumber`, "");
        formikRef.current.setFieldValue(`containers.${index}.hdiDate`, "");
        formikRef.current.setFieldValue(`containers.${index}.hdiComments`, "");
        formikRef.current.setTouched();
      });
    }
    setShow(false);
  };
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <Stack gap={4}>
        <FieldArray name='containers'>
          {({ remove, push }) => (
            <Stack gap={3}>
              {values?.containers?.length > 0 &&
                values?.containers.map((container, containerIndex) => {
                  const containerNumber = containerIndex + 1;
                  return (
                    <Stack gap={3} key={containerIndex} className={containerIndex > 0 ? "mt-2" : ""}>
                      {container.suppliers.length >= 1 &&
                        container.suppliers.map((supplier, index) => {
                          return (
                            <Stack gap={3} key={index}>
                              <Row key={index}>
                                <Col>
                                  <h4>
                                    Kontener #{containerNumber}: {supplier?.weight}kg (
                                    {container?.rawMaterial ? JSON.parse(rawMaterialDictionaryData.find((obj) => obj.id === container?.rawMaterial)?.body).shortName : ""}) od{" "}
                                    {supplier?.name ? JSON.parse(supplierDictionaryData.find((obj) => obj?.id === supplier?.name)?.body).shortName : ""}
                                  </h4>
                                </Col>
                              </Row>
                              <Row>
                                <FormField isDisabled={!isEditable} as={Col} sm='4' label='Numer' type='text' name={`containers.${containerIndex}.suppliers.${index}.hdiNumber`} />
                                <DatePickerField isDisabled={!isEditable} as={Col} sm='4' label='Data' name={`containers.${containerIndex}.suppliers.${index}.hdiDate`} />
                              </Row>
                              <Row>
                                <FormField isDisabled={!isEditable} as={Col} sm='8' label='Uwagi' type='text-area' name={`containers.${containerIndex}.suppliers.${index}.hdiComments`} />
                              </Row>
                            </Stack>
                          );
                        })}
                    </Stack>
                  );
                })}
            </Stack>
          )}
        </FieldArray>
        {edit && isEditable && (
          <Row>
            <Stack direction='horizontal' gap={2}>
              <Button variant='danger' onClick={() => handleShow()}>
                Wyczyść HDI
              </Button>
            </Stack>
          </Row>
        )}
        <Row>
          {handleNextStep && handlePrevStep && (
            <Stack direction='horizontal' gap={3}>
              <Button onClick={() => handleNextStep(values)} disabled={!isValid || isSubmitting}>
                Kontynuuj
              </Button>
              <Button variant='danger' onClick={() => handlePrevStep(values)}>
                Cofnij
              </Button>
            </Stack>
          )}
        </Row>
      </Stack>

      {show && (
        <Modal className='delete-modal' show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Czy usunąć dane z HDI?</Modal.Title>
          </Modal.Header>
          <Modal.Footer>
            <Button variant='primary' onClick={handleClose}>
              Anuluj
            </Button>
            <Button variant='danger' onClick={handleClean}>
              Potwierdź
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </>
  );
};

export default HDIControls;

HDIControls.defaultProps = {
  isEditable: true,
};
