import styled from "styled-components";

export const Circle = styled.div`
  height: 50px;
  width: 50px;
  min-width: 50px;
  border: 2px solid;
  border-radius: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;

  &.done {
    color: #0a58ca;
    border-color: #0a58ca;
  }
  &.active {
    color: #fff;
    background-color: #0b5ed7;
    border-color: #0a58ca;
  }
  &.idle {
    color: #6c757d;
    border-color: #6c757d;
  }
`;

export const Text = styled.span`
  font-weight: bold;
  white-space: nowrap;
`;

export const Line = styled.div`
  border: 1px solid #0a58ca;
  height: 1px;
  width: 100%;
`;
