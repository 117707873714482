/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useRef } from "react";
import { Formik, Form, FieldArray, getIn } from "formik";
import { Col, InputGroup, Row, Button, Stack } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";

import FormField from "components/Inputs/FormField";
import FormSelect from "components/Inputs/FormSelect";
import * as Modal from "components/Modal";
import Suppliers from "components/forms/Suppliers";

import FormDebug from "components/debug/FormDebug";

import { containers, suppliers } from "./initialValues";
import { validationSchema } from "./validationSchema";

const Delivery = (props) => {
  const { initialValues, onSubmit, handleNextStep, isLoading } = props;
  const formikRef = useRef();
  const [isOpenDriver, setOpenDriver] = useState(false);
  const [isOpenVehicle, setOpenVehicle] = useState(false);
  const [isOpenRaw, setOpenRaw] = useState(false);
  const [isOpenContractor, setOpenContractor] = useState(false);
  const [preselectType, setPreselectType] = useState("");
  const [containerType, setContainerType] = useState(3);
  const [initialValuesState, setInitialValuesState] = useState(initialValues);

  const navigate = useNavigate();
  const { form } = useParams();

  useEffect(() => {
    if (form === "schedule" && formikRef.current && initialValues) {
      const newInitialValues = { ...initialValues };
      newInitialValues.containers.forEach((container) => {
        container.weighingProtocol = null;
        container.realWeight = null;
        container.containerNumber = null;
        container.vehicle = null;
        container.type = 2;
      });

      setContainerType(2);

      newInitialValues.driver = null;
      setInitialValuesState(newInitialValues);
      formikRef.current.setValues(newInitialValues);

      const touched = {};
      Object.keys(newInitialValues).forEach((key) => {
        if (getIn(newInitialValues, key) !== undefined) {
          touched[key] = true;
        }
      });
      formikRef.current.setTouched(touched);
    } else {
      setInitialValuesState(initialValues);
    }
  }, [form, initialValues]);

  if (isLoading) return <div>Loading...</div>;

  return (
    <Stack gap={2}>
      <Row>
        <Formik
          initialValues={initialValuesState || {}}
          innerRef={formikRef}
          enableReinitialize
          validateOnMount={initialValues !== undefined ? true : false}
          validationSchema={validationSchema}
          onSubmit={(values) => onSubmit(values)}
        >
          {({ values, isValid, isSubmitting, errors }) => (
            <Form>
              <Row>
                <Col xl="8" lg="auto">
                  <Stack gap={2}>
                    <Row>
                      <Col sm="6">
                        <FormSelect
                          label="Przewoźnik"
                          name="carrier"
                          onListButtonClick={() => {
                            setOpenContractor(true);
                            setPreselectType("carrier");
                          }}
                          dictionary="carrier"
                        />
                      </Col>
                      <Col sm="6">
                        <FormSelect
                          label="Kierowca"
                          required
                          name="driver"
                          onListButtonClick={() => setOpenDriver(true)}
                          dictionary="driver"
                        />
                      </Col>
                    </Row>
                    <FieldArray name="containers">
                      {({ insert, remove, push }) => (
                        <Row>
                          {values?.containers?.length > 0 &&
                            values?.containers.map((container, index) => (
                              <Stack gap={2} key={index}>
                                <Row>
                                  <Col>
                                    <h3>Kontener #{index + 1}</h3>
                                  </Col>
                                </Row>
                                <Row>
                                  <FormField
                                    as={Col}
                                    sm="12"
                                    label="Protokół ważenia"
                                    autocomplete="off"
                                    type="text"
                                    name={`containers.${index}.weighingProtocol`}
                                  />
                                </Row>
                                <Row>
                                  <Col sm="6">
                                    <FormSelect
                                      label="Pojazd"
                                      required
                                      name={`containers.[${index}].vehicle`}
                                      onListButtonClick={() =>
                                        setOpenVehicle(true)
                                      }
                                      dictionary="vehicle"
                                    />
                                  </Col>
                                  <FormField
                                    as={Col}
                                    sm="6"
                                    required
                                    label="Numer kontenera"
                                    type="text"
                                    onInput={(e) =>
                                      (e.target.value =
                                        e.target.value.toUpperCase())
                                    }
                                    autocomplete="off"
                                    maxlength="7"
                                    name={`containers.${index}.containerNumber`}
                                  />
                                </Row>
                                <Row>
                                  <Col sm="6">
                                    <FormSelect
                                      label="Surowiec"
                                      required
                                      name={`containers.${index}.rawMaterial`}
                                      onListButtonClick={() => setOpenRaw(true)}
                                      dictionary="rawMaterial"
                                    />
                                  </Col>
                                  <FormField
                                    as={Col}
                                    sm="6"
                                    label="Waga rzeczywista"
                                    type="text"
                                    autocomplete="off"
                                    name={`containers.${index}.realWeight`}
                                    Append={
                                      <InputGroup.Text id="inputGroupPrepend">
                                        KG
                                      </InputGroup.Text>
                                    }
                                  />
                                </Row>
                                <Row>
                                  <Suppliers
                                    name={`containers.${index}.suppliers`}
                                    suppliers={suppliers}
                                    setOpen={() => {
                                      setOpenContractor(true);
                                      setPreselectType("supplier");
                                    }}
                                    container={container}
                                  />
                                </Row>
                                <Col>
                                  <Row>
                                    {index > 0 && (
                                      <Stack direction="horizontal" gap={3}>
                                        {handleNextStep ? (
                                          <Button
                                            variant="primary"
                                            onClick={() =>
                                              handleNextStep(values)
                                            }
                                            disabled={!isValid || isSubmitting}
                                          >
                                            Kontynuuj
                                          </Button>
                                        ) : (
                                          <Button
                                            variant="primary"
                                            type="submit"
                                            disabled={!isValid || isSubmitting}
                                          >
                                            Zapisz
                                          </Button>
                                        )}
                                        <Button
                                          variant="danger"
                                          onClick={() => remove(index)}
                                        >
                                          Usuń kontener
                                        </Button>
                                        <Button
                                          variant="danger"
                                          onClick={() => navigate("/")}
                                        >
                                          Anuluj
                                        </Button>
                                      </Stack>
                                    )}
                                    {index === 0 &&
                                      values?.containers?.length !== 2 && (
                                        <Stack direction="horizontal" gap={3}>
                                          {handleNextStep ? (
                                            <Button
                                              variant="primary"
                                              onClick={() =>
                                                handleNextStep(values)
                                              }
                                              disabled={
                                                !isValid || isSubmitting
                                              }
                                            >
                                              Kontynuuj
                                            </Button>
                                          ) : (
                                            <Button
                                              variant="primary"
                                              type="submit"
                                              disabled={
                                                !isValid || isSubmitting
                                              }
                                            >
                                              Zapisz
                                            </Button>
                                          )}
                                          <Button
                                            variant="primary"
                                            onClick={() =>
                                              push(containers(containerType))
                                            }
                                          >
                                            Dodaj kontener
                                          </Button>
                                          <Button
                                            variant="danger"
                                            onClick={() => navigate(-1)}
                                          >
                                            Anuluj
                                          </Button>
                                        </Stack>
                                      )}
                                  </Row>
                                </Col>
                              </Stack>
                            ))}
                        </Row>
                      )}
                    </FieldArray>
                  </Stack>
                </Col>
                <Col xl="2" lg="0">
                  <FormDebug values={values} errors={errors} />
                </Col>
              </Row>
            </Form>
          )}
        </Formik>
      </Row>
      <Modal.Driver show={isOpenDriver} onHide={() => setOpenDriver(false)} />
      <Modal.Vehicle
        show={isOpenVehicle}
        onHide={() => setOpenVehicle(false)}
      />
      <Modal.Contractor
        show={isOpenContractor}
        preselect={preselectType}
        onHide={() => setOpenContractor(false)}
      />
      <Modal.Raw show={isOpenRaw} onHide={() => setOpenRaw(false)} />
    </Stack>
  );
};

export default Delivery;
