/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import { useField, useFormikContext } from "formik";
import DatePicker, { registerLocale, setDefaultLocale } from "react-datepicker";
import pl from "date-fns/locale/pl";
import { Form } from "react-bootstrap";
registerLocale("pl", pl);
setDefaultLocale("pl");
import moment from "moment";

export const DateInput = ({ ...props }) => {
  const { label, name, required, as, sm, callback, isDisabled } = props;
  const { setFieldValue } = useFormikContext();
  const [field] = useField(props);

  return (
    <Form.Group
      as={as}
      sm={sm}
      controlId={name}>
      {label && <Form.Label className={required ? "required" : null}>{label}</Form.Label>}
      {isDisabled ? (
        <Form.Control
          {...field}
          type="date"
          size="lg"
          disabled={isDisabled}
          value={field.value || null}
        />
      ) : (
        <DatePicker
          autoComplete="off"
          todayButton="Dzisiaj"
          dateFormat="yyyy-MM-dd"
          isClearable
          isDisabled={isDisabled}
          wrapperClassName="datePicker"
          {...field}
          {...props}
          value={(field.value && new Date(field.value)) || null}
          selected={(field.value && new Date(field.value)) || null}
          onChange={(val) => {
            val !== null ? setFieldValue(field.name, moment(val).format("yyyy-MM-DD")) : setFieldValue(field.name, "");
            if (callback !== undefined) {
              callback();
            }
          }}
        />
      )}
    </Form.Group>
  );
};

export default DateInput;

DateInput.defaultProps = {
  isDisabled: false,
};
