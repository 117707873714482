export const handlContainerWeightCount = (data) => {
  let total = 0;
  data?.suppliers?.forEach((item) => {
    total += item?.weight;
  });
  return total;
};

export const handleAllWeightCount = (data, table) => {
  const weight = data?.containers?.map((container) => {
    let total = 0;
    if (table === "active" && (container.type === 3 || container.type === 4 || container.type === 5)) {
      container?.suppliers.forEach((item) => {
        total += item?.weight;
      });
    }
    if (table === "inactive" && (container.type === 6 || container.type === 7 || container.type === 8)) {
      container?.suppliers.forEach((item) => {
        total += item?.weight;
      });
    }

    return total;
  });

  const sumWeight = weight?.reduce((a, b) => a + b, 0);

  return sumWeight;
};

const handleWeightCount = (data) => {
  const weight = data?.containers?.map((container) => {
    let total = 0;
    container?.suppliers.forEach((item) => {
      total += item?.weight;
    });
    return total;
  });
  const sumWeight = weight?.reduce((a, b) => a + b, 0);
  return sumWeight;
};
export default handleWeightCount;
