export const initialValues = {
  plannedDateAndTime: "",
  carrier: "",
  containers: [
    {
      type: 1,
      rawMaterial: "",
      suppliers: [
        {
          name: "",
          weight: "",
          snc: "",
        },
      ],
    },
  ],
};

export const containers = {
  rawMaterial: "",
  type: 1,
  suppliers: [
    {
      name: "",
      weight: "",
      snc: "",
    },
  ],
};

export const suppliers = {
  name: "",
  weight: "",
  snc: "",
};
