/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Stack, Row } from "react-bootstrap";
import { Formik, Form, FieldArray } from "formik";

import FormSteps from "components/stepform/FormSteps";
import * as Modal from "components/Modal";
import FormDebug from "components/debug/FormDebug";

import DeliveryStep from "components/forms/DeliveryStep";
import HDIControls from "components/forms/HDIControls";
import Redirect from "components/forms/Redirect";
import { useDictionariesData } from "queries/useDictionaryData";

import { initialValues as deliveryInitialValues } from "components/forms/Delivery/Create/initialValues";
import { initialValues as hdiInitialValues } from "components/forms/HDIControls/initialValues";
import { initialValues as redirectInitialValues } from "components/forms/Redirect/initialValues";

const StepForm = ({ onSubmit }) => {
  let navigate = useNavigate();
  const [formStep, setFormStep] = useState(1);
  const [deliveryValues, setDeliveryValues] = useState(deliveryInitialValues);
  const [hdiControlsValues, setHDIControlsValues] = useState(hdiInitialValues);
  const [redirectValues, setRedirectValues] = useState(redirectInitialValues);

  const { data: rawMaterialDictionaryData } = useDictionariesData(3);
  const { data: supplierDictionaryData } = useDictionariesData(4);

  const [isOpenDriver, setOpenDriver] = useState(false);
  const [isOpenVehicle, setOpenVehicle] = useState(false);
  const [isOpenRaw, setOpenRaw] = useState(false);
  const [isOpenContractor, setOpenContractor] = useState(false);

  const steps = {
    nextStep: () => {
      setFormStep(formStep + 1);
    },
    prevStep: () => {
      setFormStep(formStep - 1);
    },
  };

  const handleFirstStep = (values) => {
    setDeliveryValues(values);
    steps.nextStep();
  };

  const handleSecondStep = (values) => {
    setHDIControlsValues(values);
    steps.nextStep();
  };

  const handleSecondStepBack = (values) => {
    setHDIControlsValues(values);
    steps.prevStep();
  };

  const handleThirdStepBack = (values) => {
    setRedirectValues(values);
    steps.prevStep();
  };

  const handleThirdStep = (values) => {
    handleSubmit(values);
  };

  const handleSubmit = (values) => {
    onSubmit(values);
    navigate("/");
  };

  const initialValues = {
    type: 8,
    carrier: "",
    driver: null,
    containers: [
      {
        weighingProtocol: "",
        vehicle: "",
        containerNumber: "",
        rawMaterial: "",
        realWeight: "",
        hdiNumber: "",
        hdiDate: "",
        hdiComments: "",
        redirectRecipient: null,
        redirectDeliveryDate: "",
        suppliers: [
          {
            name: "",
            weight: "",
            snc: "",
          },
        ],
      },
    ],
  };

  const formsSwitch = ({ values, isValid, isSubmitting }) => {
    switch (formStep) {
      case 1:
        return (
          <DeliveryStep
            handleNextStep={handleFirstStep}
            setOpenContractor={setOpenContractor}
            setOpenDriver={setOpenDriver}
            setOpenVehicle={setOpenVehicle}
            setOpenRaw={setOpenRaw}
            values={values}
            isValid={isValid}
            isSubmitting={isSubmitting}
          />
        );
      case 2:
        return (
          <HDIControls
            edit={false}
            handleNextStep={handleSecondStep}
            handlePrevStep={handleSecondStepBack}
            values={values}
            isValid={isValid}
            isSubmitting={isSubmitting}
            rawMaterialDictionaryData={rawMaterialDictionaryData}
            supplierDictionaryData={supplierDictionaryData}
          />
        );
      case 3:
        return <Redirect handleNextStep={handleThirdStep} handlePrevStep={handleThirdStepBack} values={values} isValid={isValid} isSubmitting={isSubmitting} />;
    }
  };

  return (
    <Stack gap={4}>
      <Row>
        <FormSteps formStep={formStep} />
      </Row>
      <Stack gap={2}>
        <Row>
          <Formik validateOnMount={false} initialValues={initialValues} enableReinitialize onSubmit={(values) => handleSubmit(values)}>
            {({ values, isValid, isSubmitting, errors }) => (
              <Form>
                {formsSwitch({ values, isValid, isSubmitting })}
                <FormDebug values={values} />
              </Form>
            )}
          </Formik>
        </Row>
        <Modal.Driver show={isOpenDriver} onHide={() => setOpenDriver(false)} />
        <Modal.Vehicle show={isOpenVehicle} onHide={() => setOpenVehicle(false)} />
        <Modal.Contractor show={isOpenContractor} onHide={() => setOpenContractor(false)} />
        <Modal.Raw show={isOpenRaw} onHide={() => setOpenRaw(false)} />
      </Stack>
    </Stack>
  );
};

export default StepForm;
