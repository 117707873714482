import { useQuery } from "react-query";
import { request } from "../utils/axios";
import toast from "react-hot-toast";

const fetchAuthorizations = () => {
  return request({ url: "/authorizations", method: "get" });
};

export const useAuthorizationsData = () => {
  return useQuery(["authorizations"], () => fetchAuthorizations(), {
    refetchOnWindowFocus: false,
    onError: (error) => {
      toast.error(`Error on background update: ${error.message}`);
    },
    select: (data) => {
      const Authorization = data?.data;
      return Authorization;
    },
  });
}
