import React, { createContext, useContext, useEffect, useMemo, useState, useCallback } from "react";

const initialValues = {
  id: "",
  regDate: "",
  carrier: null,
  rawMaterial: null,
  supplier: null,
  snc: "",
  place: "",
};

const DeliveryContext = createContext();

const useDelivery = () => {
  return useContext(DeliveryContext);
};

const useDeliveryProvider = () => {
  const [topFilters, setTopFilters] = useState(initialValues);
  const [filters, setFilters] = useState(initialValues);
  const [page, setPage] = useState(0);
  const [query, setQuery] = useState();
  const [status, setStatus] = useState();

  const resetPage = useCallback(() => {
    setPage(0);
    setTopFilters({ ...topFilters, place: "" });
  }, [setPage, setTopFilters, topFilters]);

  useEffect(() => {
    let query = "";
    for (const [key, value] of Object.entries(topFilters)) {
      if (value !== "" && value !== null && value !== undefined) {
        query += `&${key}=${value}`;
      }
    }

    if (query !== filters) {
      setFilters(query);
    }
  }, [topFilters, setFilters, filters]);

  useEffect(() => {
    if (status) {
      setQuery(`?page=${page}` + status + Object.values(filters).join(""));
    }
  }, [status, filters, page]);

  const memoizedDelivery = useMemo(() => {
    return {
      topFilters,
      setTopFilters,
      filters,
      page,
      setPage,
      resetPage,
      query,
      setQuery,
      status,
      setStatus,
    };
  }, [topFilters, setTopFilters, filters, page, resetPage, query, status, setStatus]);

  return memoizedDelivery;
};

function DeliveryProvider({ children }) {
  const delivery = useDeliveryProvider();
  return <DeliveryContext.Provider value={delivery}>{children}</DeliveryContext.Provider>;
}

const MemoizedDeliveryProvider = React.memo(DeliveryProvider);

export { MemoizedDeliveryProvider as DeliveryProvider, useDelivery };
