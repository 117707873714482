/* eslint-disable no-unused-vars */
import React from "react";

import * as yup from "yup";

import FormField from "components/Inputs/FormField";
import ModalWrapper from "../Template/";
import { useAddUserData } from "queries/useUsersData";

const User = (props) => {
  const { onHide } = props;

  const { mutate } = useAddUserData();

  const initialValues = {
    name: "",
    email: "",
    password: "",
    roles: [],
  };

  const schema = yup.object({
    name: yup.string().required("Pole wymagane"),
    email: yup.string().required("Pole wymagane"),
    password: yup.string().required("Pole wymagane"),
  });

  const onSubmit = (values) => {
    mutate(values);
  };

  return (
    <ModalWrapper {...props} schema={schema} initial={initialValues} onSubmit={(values) => onSubmit(values)} title='Dodaj użytkownika' onHide={onHide}>
      <FormField required label='Imię' type='text' name='name' />
      <FormField required label='Email' type='text' name='email' />
      <FormField required label='Hasło' type='text' name='password' />
      {/*<FormField label='Role' type='text' name='roles' />*/}
    </ModalWrapper>
  );
};

export default User;
