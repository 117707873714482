import React from "react";
import { Navbar } from "react-bootstrap";

import Icons from "components/Icons";
import AuthService from "services/auth.service";

const Navigation = () => {
  const user = AuthService.getCurrentUser();

  return (
    (user && user.token) && (
      <Navbar style={{ borderBottom: "1px solid #ECECEC" }}>
        <Navbar.Collapse className="justify-content-end mx-4">
          <Navbar.Text >
            <span className="mx-1">
              <Icons.Person />
            </span>
            <span>{user.name}</span>
          </Navbar.Text>
        </Navbar.Collapse>
      </Navbar>
    )
  )
};

export default Navigation;
