import React, { useState, useRef, useEffect } from "react";
import { Formik, Form, FieldArray } from "formik";
import { Col, Row, Button, Stack, SplitButton, Dropdown, Modal as ModalB } from "react-bootstrap";

// import FormField from "components/Inputs/FormField";
import FormSelect from "components/Inputs/FormSelect";
import DatePickerField from "components/Inputs/DataTime";
import * as Modal from "components/Modal";
import Suppliers from "components/forms/Suppliers";
import AuthService from "services/auth.service";

import { useDeleteSingleDeliveries } from "queries/useDeliveriesData";

import moment from "moment";

import FormDebug from "components/debug/FormDebug";

import { containers, suppliers } from "./initialValues";
import { validationSchema } from "./validationSchema";

const Schedule = (props) => {
  const { initialValues, formVariant, navigate, onSubmit } = props;
  const [isOpenRaw, setOpenRaw] = useState(false);
  const [isOpenContractor, setOpenContractor] = useState(false);
  const [show, setShow] = useState(false);
  const formikRef = useRef();

  const { mutate } = useDeleteSingleDeliveries();

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleDelete = () => {
    mutate(initialValues?.id);
    setShow(false);
  };

  useEffect(() => {
    if (formikRef.current) {
      formikRef.current.setFieldValue("plannedDateAndTime", moment().format("yyyy-MM-DDT00:00"));
    }
  }, [formikRef]);

  return (
    <Stack gap={3}>
      <Row>
        <Formik initialValues={initialValues} innerRef={formikRef} validateOnMount={true} enableReinitialize validationSchema={validationSchema} onSubmit={(values) => onSubmit(values)}>
          {({ values, isValid, isSubmitting }) => (
            <Form>
              <Row>
                <Col xl="8" lg="auto">
                  <Stack gap={3}>
                    <Row>
                      <DatePickerField sm="4" as={Col} label="Planowana data i czas" name="plannedDateAndTime" />
                      <Col>
                        <FormSelect label="Przewoźnik" name="carrier" onListButtonClick={() => setOpenContractor(true)} dictionary="carrier" />
                      </Col>
                    </Row>
                    <FieldArray name="containers">
                      {({ remove, push }) => (
                        <Row>
                          {values?.containers?.length > 0 &&
                            values?.containers.map((container, index) => (
                              <Stack gap={3} key={index}>
                                <Row>
                                  <Col>
                                    <h3>Kontener #{index + 1}</h3>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col>
                                    <FormSelect label="Surowiec" required name={`containers.${index}.rawMaterial`} dictionary="rawMaterial" />
                                  </Col>
                                </Row>
                                <Row>
                                  <Suppliers name={`containers.${index}.suppliers`} suppliers={suppliers} onListButtonClick={() => setOpenContractor(true)} container={container} />
                                </Row>
                                <Col>
                                  <Row>
                                    {index > 0 && (
                                      <Stack direction="horizontal" gap={3}>
                                        <Button variant="success" type="submit" disabled={!isValid || isSubmitting}>
                                          Zapisz
                                        </Button>
                                        <Button variant="danger" onClick={() => remove(index)}>
                                          Usuń kontener
                                        </Button>
                                        {formVariant === "edit" && (
                                          <>
                                            <Button variant="warning" onClick={() => navigate(-1)}>
                                              Anuluj
                                            </Button>
                                            {AuthService.userCan("usun_plan_dostawy") && (
                                              <Button variant="danger" onClick={() => handleShow()}>
                                                Usuń plan dostawy
                                              </Button>
                                            )}
                                          </>
                                        )}
                                      </Stack>
                                    )}
                                    {index === 0 && values?.containers?.length !== 2 && (
                                      <Stack direction="horizontal" gap={3}>
                                        <Button variant="success" type="submit" disabled={!isValid || isSubmitting}>
                                          Zapisz
                                        </Button>
                                        <SplitButton align={{ lg: "start" }} onClick={() => push(containers)} id="dropdown-item-button" title="Dodaj kontener">
                                          <Dropdown.Item onClick={() => push(container)} as="button">
                                            Kopia pierwszego
                                          </Dropdown.Item>
                                        </SplitButton>
                                        {formVariant === "edit" && (
                                          <>
                                            <Button variant="warning" onClick={() => navigate(-1)}>
                                              Anuluj
                                            </Button>
                                            {AuthService.userCan("usun_plan_dostawy") && (
                                              <Button variant="danger" onClick={() => handleShow()}>
                                                Usuń plan dostawy
                                              </Button>
                                            )}
                                          </>
                                        )}
                                      </Stack>
                                    )}
                                  </Row>
                                </Col>
                              </Stack>
                            ))}
                        </Row>
                      )}
                    </FieldArray>
                  </Stack>
                </Col>
                <Col xl="2" lg="0">
                  <FormDebug values={values} />
                </Col>
              </Row>
            </Form>
          )}
        </Formik>
      </Row>

      {formVariant === "edit" && (
        <ModalB className="delete-modal" show={show} onHide={handleClose}>
          <ModalB.Header closeButton>
            <ModalB.Title>Czy usunąć plan dostawy?</ModalB.Title>
          </ModalB.Header>
          <ModalB.Footer>
            <Button variant="primary" onClick={handleClose}>
              Anuluj
            </Button>
            <Button variant="danger" onClick={handleDelete}>
              Potwierdź
            </Button>
          </ModalB.Footer>
        </ModalB>
      )}
      <Modal.Contractor show={isOpenContractor} type="carrier" onHide={() => setOpenContractor(false)} />
      <Modal.Raw show={isOpenRaw} onHide={() => setOpenRaw(false)} />
    </Stack>
  );
};

export default Schedule;

Schedule.defaultProps = {
  formVariant: null,
  navigate: null,
};
