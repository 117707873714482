import * as yup from "yup";

export const validationSchema = yup.object({
  driver: yup.number().required("Pole wymagane").nullable(),
  containers: yup.array().of(
    yup.object().shape({
      vehicle: yup.number().required("Pole wymagane").nullable(),
      containerNumber: yup.string().required("Pole wymagane"),
      rawMaterial: yup.number().required("Pole wymagane").nullable(),
      suppliers: yup.array().of(
        yup.object().shape({
          name: yup.number().required("Pole wymagane").nullable(),
          weight: yup.string().required("Pole wymagane"),
          snc: yup.string(),
        })
      ),
    })
  ),
});
