export const initialValues = {
  carrier: "",
  driver: null,
  containers: [
    {
      type: 3,
      weighingProtocol: "",
      vehicle: "",
      containerNumber: "",
      rawMaterial: "",
      realWeight: "",
      suppliers: [
        {
          name: "",
          weight: "",
          snc: "",
        },
      ],
    },
  ],
};

export const initialValuesNew = {
  carrier: "",
  driver: null,
  containers: [
    {
      weighingProtocol: "",
      vehicle: "",
      containerNumber: "",
      rawMaterial: "",
      realWeight: "",
      type: 3,
      suppliers: [
        {
          name: "",
          weight: "",
          snc: "",
        },
      ],
    },
  ],
};

export const initialValuesNewSchedule = {
  carrier: "",
  driver: null,
  plannedDateAndTime: "",
  containers: [
    {
      weighingProtocol: "",
      vehicle: "",
      containerNumber: "",
      rawMaterial: "",
      realWeight: "",
      type: 2,
      suppliers: [
        {
          name: "",
          weight: "",
          snc: "",
        },
      ],
    },
  ],
};

export const containers = (type) => {
  return {
    weighingProtocol: "",
    vehicle: "",
    containerNumber: "",
    raw: "",
    realWeight: "",
    type: type,
    suppliers: [
      {
        name: "",
        weight: "",
        snc: "",
      },
    ],
  };
};

export const suppliers = {
  name: "",
  weight: "",
  snc: "",
};
