import React from "react";
import { Button, DropdownButton, Dropdown } from "react-bootstrap";
import AuthService from "services/auth.service";
import { acceptanceTypeStatus } from "models/acceptanceTypeStatus";

const handleStatus = (props) => {
  const { data, handleStatusChange } = props;
  const { type, acceptanceType } = data;

  switch (true) {
    case type === 3 && acceptanceType === null:
      return (
        <>
          <Button
            onClick={() => handleStatusChange({ data, status: acceptanceTypeStatus.ADMISSION_FIRST })}
            variant="danger"
            disabled={!AuthService.userCan("zmien_status_przyjecie")}
            size="sm">
            przyjęcie
          </Button>

          <Button
            onClick={() => handleStatusChange({ data, status: acceptanceTypeStatus.ENTRY_FIRST })}
            variant="danger"
            disabled
            size="sm">
            wjazd
          </Button>
        </>
      );

    case type === 4 && acceptanceType === acceptanceTypeStatus.ADMISSION_FIRST:
      return (
        <>
          <Button
            disabled
            variant="success"
            className="blink"
            size="sm">
            przyjęcie
          </Button>

          <Button
            onClick={() => handleStatusChange({ data, status: acceptanceTypeStatus.NOT_DISCHARGED })}
            variant="danger"
            disabled={!AuthService.userCan("zmien_status_wjazd")}
            size="sm">
            wjazd
          </Button>
        </>
      );

    case type === 4 && acceptanceType === acceptanceTypeStatus.ENTRY_FIRST:
      return (
        <>
          <Button
            onClick={() => handleStatusChange({ data, status: acceptanceTypeStatus.NOT_DISCHARGED })}
            variant="danger"
            disabled={!AuthService.userCan("zmien_status_przyjecie")}
            size="sm">
            przyjęcie
          </Button>

          <Button
            variant="success"
            disabled
            size="sm">
            wjazd
          </Button>
        </>
      );

    case type === 4 && acceptanceType === acceptanceTypeStatus.NOT_DISCHARGED:
      return (
        <DropdownButton
          id="dropdown-item-button"
          title="NIE ROZŁADOWANY">
          {AuthService.userCan("zmien_status_rozladunku_produkcja") && (
            <Dropdown.Item
              as="button"
              onClick={() => handleStatusChange({ data, status: acceptanceTypeStatus.UNLOADING_PRODUCTION })}>
              Rozładunek do produkcji
            </Dropdown.Item>
          )}
          {AuthService.userCan("zmien_status_rozladunku_plac") && (
            <Dropdown.Item
              as="button"
              onClick={() => handleStatusChange({ data, status: acceptanceTypeStatus.UNLOADING_YARD })}>
              Rozładunek na plac
            </Dropdown.Item>
          )}
        </DropdownButton>
      );

    case type === 5 && acceptanceType === acceptanceTypeStatus.UNLOADING_YARD:
      return (
        <DropdownButton
          id="dropdown-item-button"
          title="NA PLACU">
          {AuthService.userCan("zmien_status_przyjecie_produkcja") && (
            <Dropdown.Item
              as="button"
              onClick={() => handleStatusChange({ data, status: acceptanceTypeStatus.ACCEPTANCE_PRODUCTION })}>
              Przyjęcie do produkcji
            </Dropdown.Item>
          )}
          {AuthService.userCan("zmien_status_wywoz") && (
            <Dropdown.Item
              as="button"
              onClick={() => handleStatusChange({ data, status: acceptanceTypeStatus.PLANT_EXPORT })}>
              Wywóz z zakładu
            </Dropdown.Item>
          )}
        </DropdownButton>
      );

    case type === 4 && acceptanceType === acceptanceTypeStatus.ACCEPTANCE_PRODUCTION:
      return (
        <>
          {AuthService.userCan("zmien_status_przyjecie") && (
            <Button
              style={{ minWidth: "100px" }}
              disabled
              variant="success"
              className="blink"
              size="sm">
              przyjęcie do produkcji
            </Button>
          )}
          {(AuthService.userCan("zmien_status_wjazd") || AuthService.userCan("zmien_status_przyjecie")) && (
            <Button
              style={{ minWidth: "100px" }}
              onClick={() => handleStatusChange({ data, status: acceptanceTypeStatus.UNLOADING_PRODUCTION })}
              variant="danger"
              size="sm">
              rozładunek do produkcji
            </Button>
          )}
        </>
      );
    case type === 6 && acceptanceType === acceptanceTypeStatus.UNLOADING_PRODUCTION:
      return (
        <DropdownButton
          id="dropdown-item-button"
          title="ROZŁADOWANY">
          {/* {AuthService.userCan("zmien_status_wywoz_z_zakladu") && (
                )} */}
          <Dropdown.Item
            as="button"
            onClick={() => handleStatusChange({ data, status: acceptanceTypeStatus.PLANT_EXPORT })}>
            Wywóz z zakładu
          </Dropdown.Item>
        </DropdownButton>
      );
    case type === 7 && acceptanceType === acceptanceTypeStatus.PLANT_EXPORT:
      return <p>WYWIEZIONY</p>;

    case type === 8:
      return <p>PRZEKIEROWANY</p>;

    default:
      return <></>;
  }
};
export default handleStatus;
