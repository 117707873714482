/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";

import * as yup from "yup";
import { Row, Stack, Col, Modal, Button, Badge } from "react-bootstrap";
import { FieldArray, Formik, Form } from "formik";

import Icons from "components/Icons/";
import FormField from "components/Inputs/FormField";
import FormSelect from "components/Inputs/FormSelect";
import FormDebug from "components/debug/FormDebug";

import { useUpdateContainerData } from "queries/useContainerData";

const Removal = (props) => {
  const [initialValues, setInitialValues] = useState({
    id: null,
    type: 7,
    acceptanceType: 6,
    removalRecipient: null,
    removalComments: "",
  });

  const { onHide, data } = props;
  const { mutate } = useUpdateContainerData();

  const validationSchema = yup.object({
    removalRecipient: yup.number().required("Pole wymagane").nullable(),
    removalComments: yup.string().required("Pole wymagane"),
  });

  useEffect(() => {
    setInitialValues({
      id: data?.data?.id,
      type: 7,
      acceptanceType: data?.status,
      removalRecipient: null,
      removalComments: "",
    });
  }, [data?.data, data?.status]);

  return (
    <Modal {...props} onHide={onHide} aria-labelledby='contained-modal-title-vcenter' centered>
      <Modal.Header closeButton>
        <Modal.Title className='d-flex align-middle' id='contained-modal-title-vcenter'>
          <Icons.Warning /> <span className='modal-title'>Zarejestrować wywóz surowca?</span>
        </Modal.Title>
      </Modal.Header>
      <Formik
        initialValues={initialValues}
        enableReinitialize
        validateOnMount={false}
        validationSchema={validationSchema}
        onSubmit={(values, { setSubmitting }) => {
          mutate(values);
          setTimeout(() => {
            onHide();
          }, 500);
        }}>
        {({ values, isValid, error }) => (
          <Form>
            <Modal.Body>
              <Stack gap={3} className='p-2'></Stack>
              <Stack gap={4}>
                <FieldArray name='containers'>
                  {() => (
                    <Row>
                      <div>
                        <p>
                          Kontener # {data?.data.containerNumber}: ({data?.data?.rawMaterialString !== undefined ? JSON.parse(data?.data.rawMaterialString).shortName : ""})
                        </p>
                        <ul>
                          {data?.data?.suppliers?.map((supplier, index) => {
                            return (
                              <li key={index}>
                                {supplier.weight}kg od {supplier?.nameString !== undefined ? JSON.parse(supplier.nameString).shortName : ""}
                                {"  "}
                                <Badge bg='warning' text='dark'>
                                  {supplier.snc}
                                </Badge>
                              </li>
                            );
                          })}
                        </ul>
                      </div>
                    </Row>
                  )}
                </FieldArray>
                <Row>
                  <Col>
                    <FormSelect label='Odbiorca' required dictionary='recipient' name='removalRecipient' />
                  </Col>
                </Row>
                <Row>
                  <FormField as={Col} required label='Uwagi' type='text' name='removalComments' />
                </Row>
              </Stack>
            </Modal.Body>
            <Modal.Footer>
              <Button type='submit' disabled={!isValid}>
                Zapisz
              </Button>
              <Button onClick={onHide}>Anuluj</Button>
            </Modal.Footer>
            <FormDebug values={values} />
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

export default Removal;
