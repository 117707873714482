/* eslint-disable no-unused-vars */
import React, { useEffect, useRef, useState } from "react";

import * as yup from "yup";

import FormField from "components/Inputs/FormField";
import { useAddRoleData, useRolesData } from "queries/useUsersRolesData";

import { Formik, Form } from "formik";
import { Modal, Button, Stack, Col, Row } from "react-bootstrap";
import FormDebug from "components/debug/FormDebug";
import Icons from "components/Icons/";

import { useAuthorizationsData } from "queries/useAuthorizationsData";

const Role = (props) => {
  const { onHide, data: roleData } = props;
  const { data: _data } = useAuthorizationsData();
  const { mutate } = useAddRoleData();

  const [data, setData] = useState([]);

  const [selected, setSelected] = useState([]);

  const formikRef = useRef();

  useEffect(() => {
    if (roleData !== undefined) {
      setSelected(JSON.parse(roleData.assigned_authorizations));
    } else setSelected([]);
  }, [roleData]);

  useEffect(() => {
    if (_data !== undefined) {
      setData(_data);
    }
  }, [_data]);

  const initialValues = {
    name: roleData !== undefined ? roleData.name : "",
    assigned_authorizations: roleData !== undefined ? JSON.parse(roleData.assigned_authorizations) : [],
  };

  const schema = yup.object({
    name: yup.string().required("Pole wymagane"),
  });

  const onSubmit = (values) => {
    values.assigned_authorizations = JSON.stringify(selected);
    if (roleData !== undefined) {
      values.id = roleData.id;
    }
    mutate(values);
  };

  return (
    <Modal {...props} size='lg' onHide={onHide} aria-labelledby='contained-modal-title-vcenter' centered>
      <Modal.Header closeButton>
        <Modal.Title className='d-flex align-middle' id='contained-modal-title-vcenter'>
          <Icons.Warning /> {roleData !== undefined ? "Edytuj Role" : "Dodaj Role"}
        </Modal.Title>
      </Modal.Header>
      <Formik
        initialValues={roleData !== undefined ? roleData : initialValues}
        noValidate
        enableReinitialize
        validationSchema={schema}
        innerRef={formikRef}
        onSubmit={(values) => {
          onSubmit(values);
          onHide();
        }}>
        {({ values, isValid }) => (
          <Form>
            <Modal.Body>
              <Stack gap={3} className='p-2'>
                <FormField required label='Nazwa' type='text' name='name' />
                <Row>
                  <Col xs={5}>
                    <select style={{ width: "100%", minHeight: "300px" }} className='base-role-select' multiple={true}>
                      {data.map((option, key) => !selected.includes(option) && <option key={key}>{option}</option>)}
                    </select>
                  </Col>
                  <Col
                    xs={2}
                    style={{
                      textAlign: "center",
                    }}>
                    <button
                      className={"btn btn-success"}
                      type='button'
                      onClick={(e) => {
                        let selectedItems = Array.from(window.document.querySelector(".base-role-select").options)
                          .filter((option) => option.selected)
                          .map((option) => option.value);
                        setSelected(selected.concat(selectedItems));
                      }}>
                      {">"}
                    </button>
                    <br />
                    <button
                      className={"btn btn-danger"}
                      type='button'
                      onClick={(e) => {
                        let selectedItems = Array.from(window.document.querySelector(".assigned-role-select").options)
                          .filter((option) => option.selected)
                          .map((option) => option.value);
                        setSelected(selected.filter((item) => !selectedItems.includes(item)));
                      }}>
                      {"<"}
                    </button>
                    <br />
                    <button
                      className={"btn btn-success"}
                      type='button'
                      onClick={(e) => {
                        setSelected(data);
                      }}>
                      {">>"}
                    </button>
                    <br />
                    <button
                      className={"btn btn-danger"}
                      type='button'
                      onClick={(e) => {
                        setSelected([]);
                      }}>
                      {"<<"}
                    </button>
                  </Col>
                  <Col xs={5}>
                    <select
                      style={{
                        width: "100%",
                        minHeight: "300px",
                      }}
                      name='assigned_authorizations'
                      className='assigned-role-select'
                      multiple={true}>
                      {selected.map((option, key) => (
                        <option key={key}>{option}</option>
                      ))}
                    </select>
                  </Col>
                </Row>
              </Stack>
              <FormDebug values={values} />
            </Modal.Body>
            <Modal.Footer>
              <Button type='submit' disabled={!isValid}>
                Ok
              </Button>
              <Button onClick={onHide}>Anuluj</Button>
            </Modal.Footer>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

export default Role;
