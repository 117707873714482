/* eslint-disable indent */
/* eslint-disable no-unused-vars */
import React, { useState, useRef, useEffect } from "react";
import { Formik, Form } from "formik";
import { Col, Modal, Button, Badge, Stack } from "react-bootstrap";
import * as yup from "yup";

import FormField from "components/Inputs/FormField";
import Icons from "components/Icons/";
import { useUpdateContainerData } from "queries/useContainerData";
import FormDebug from "components/debug/FormDebug";

const Acceptance = (props) => {
  const [initialValues, setInitialValues] = useState({
    id: null,
    type: 4,
    acceptanceType: 1,
  });
  const { onHide, data } = props;

  const { mutate } = useUpdateContainerData();

  useEffect(() => {
    if (data?.data !== undefined) {
      switch (true) {
        case data?.data?.type === 3 && data?.status === 1:
          setInitialValues({
            id: data?.data?.id,
            type: 4,
            acceptanceType: 1,
          });
          break;
        case data?.data?.type === 3 && data?.status === 2:
          setInitialValues({
            id: data?.data?.id,
            type: 4,
            acceptanceType: 2,
          });
          break;
        case data?.data.type === 4 && data?.status === 3:
          setInitialValues({
            id: data?.data?.id,
            type: 4,
            acceptanceType: 3,
          });
          break;
        case data?.data.type === 5 && data?.status === 7:
          setInitialValues({
            id: data?.data?.id,
            type: 4,
            acceptanceType: 7,
          });
          break;
        default:
          break;
      }
    }
  }, [data?.data, data?.status]);

  return (
    <Modal {...props} onHide={onHide} aria-labelledby='contained-modal-title-vcenter' centered>
      <Modal.Header closeButton>
        <Modal.Title className='d-flex align-middle' id='contained-modal-title-vcenter'>
          <Icons.Warning /> <span className='modal-title'>{data?.status === 1 ? "Zarejestrować przyjęcie surowca?" : "Zarejestrować wjazd surowca?"}</span>
        </Modal.Title>
      </Modal.Header>
      <Formik
        initialValues={initialValues}
        enableReinitialize
        onSubmit={(values, { setSubmitting }) => {
          mutate(values);
          setTimeout(() => {
            onHide();
          }, 500);
        }}>
        {({ values, isValid, isSubmitting, errors }) => (
          <Form>
            <Modal.Body>
              <Stack>
                <div>
                  <p>
                    Kontener # {data?.data.containerNumber}: ({data?.data?.rawMaterialString !== undefined ? JSON.parse(data?.data.rawMaterialString).shortName : ""})
                  </p>
                  <ul>
                    {data?.data?.suppliers?.map((supplier, index) => {
                      return (
                        <li key={index}>
                          {supplier.weight}kg od {supplier?.nameString !== undefined ? JSON.parse(supplier.nameString).shortName : ""}
                          {"  "}
                          <Badge bg='warning' text='dark'>
                            {supplier.snc}
                          </Badge>
                        </li>
                      );
                    })}
                  </ul>
                </div>
                <FormDebug values={values} />
              </Stack>
            </Modal.Body>
            <Modal.Footer>
              <Button type='submit' disabled={!isValid || isSubmitting}>
                Zapisz
              </Button>
              <Button onClick={onHide}>Anuluj</Button>
            </Modal.Footer>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

export default Acceptance;
