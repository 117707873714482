/* eslint-disable react/react-in-jsx-scope */
import handleDateFormat from "handlers/handleDateFormat";
import { acceptanceTypeStatus } from "models/acceptanceTypeStatus";

const handleHistoryText = (props) => {
  const { state_to, acceptance_type_to, acceptance_type_from, created_at } = props;

  switch (true) {
    case state_to === 3 && acceptance_type_to === null:
      return <></>;

    case state_to === 4 && acceptance_type_to === acceptanceTypeStatus.ADMISSION_FIRST:
      return <span>Przyjęcie - {handleDateFormat(handleDateFormat(created_at))}</span>;

    case state_to === 4 && acceptance_type_to === acceptanceTypeStatus.ENTRY_FIRST:
      return <span>Wjazd - {handleDateFormat(created_at)}</span>;

    case state_to === 4 && acceptance_type_to === acceptanceTypeStatus.NOT_DISCHARGED && acceptance_type_from === acceptanceTypeStatus.ADMISSION_FIRST:
      return <span>Wjazd: {handleDateFormat(created_at)}</span>;

    case state_to === 4 && acceptance_type_to === acceptanceTypeStatus.NOT_DISCHARGED && acceptance_type_from === acceptanceTypeStatus.ENTRY_FIRST:
      return <span>Przyjęcie: {handleDateFormat(created_at)}</span>;

    case state_to === 5 && acceptance_type_to === acceptanceTypeStatus.UNLOADING_YARD:
      return <span>Rozładunek na placu - {handleDateFormat(created_at)}</span>;

    case state_to === 4 && acceptance_type_to === acceptanceTypeStatus.ACCEPTANCE_PRODUCTION:
      return <span>Przyjęcie do produkcji - {handleDateFormat(created_at)}</span>;

    case state_to === 6 && acceptance_type_to === acceptanceTypeStatus.UNLOADING_PRODUCTION:
      return <span>Rozładunek na produkcji - {handleDateFormat(created_at)}</span>;

    case state_to === 7 && acceptance_type_to === acceptanceTypeStatus.PLANT_EXPORT:
      return <span>Wywóz z zakładu - {handleDateFormat(created_at)}</span>;

    case state_to === 8:
      return <span>Przekierowany - {handleDateFormat(created_at)}</span>;
    default:
      return <></>;
  }
};
export default handleHistoryText;
