/* eslint-disable no-unused-vars */
import React, { useRef } from "react";
import { Formik, Form } from "formik";
import { Modal, Button, Stack } from "react-bootstrap";
import FormDebug from "components/debug/FormDebug";

import Icons from "components/Icons/";

const ModalWrapper = (props) => {
  const { onHide, initial, schema, onSubmit, title, children } = props;

  return (
    <Modal {...props} size='lg' onHide={onHide} aria-labelledby='contained-modal-title-vcenter' centered>
      <Modal.Header closeButton>
        <Modal.Title className='d-flex align-middle' id='contained-modal-title-vcenter'>
          <Icons.Warning /> {title}
        </Modal.Title>
      </Modal.Header>
      <Formik
        initialValues={initial}
        noValidate
        enableReinitialize
        validationSchema={schema}
        onSubmit={(values) => {
          onSubmit(values);
          onHide();
        }}>
        {({ values, isValid }) => (
          <Form>
            <Modal.Body>
              <Stack gap={3} className='p-2'>
                {children}
              </Stack>
              <FormDebug values={values} />
            </Modal.Body>
            <Modal.Footer>
              <Button type='submit' disabled={!isValid}>
                Ok
              </Button>
              <Button onClick={onHide}>Anuluj</Button>
            </Modal.Footer>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

export default ModalWrapper;

ModalWrapper.defaultProps = {
  schema: {},
  onSubmit: () => alert("brak on submit"),
  title: "Brak tytułu",
  children: null,
};
