import React from "react";
import { Form, InputGroup } from "react-bootstrap";
import { Field } from "formik";

const InputGroupWrapper = (props) => {
  const { Prepend, Append, children, meta } = props;

  if (Prepend || Append) {
    return (
      <InputGroup>
        {Prepend && Prepend}
        {children}
        {Append && Append}
        <Form.Control.Feedback type="invalid">{meta.error}</Form.Control.Feedback>
      </InputGroup>
    );
  }
  return (
    <>
      {children} <Form.Control.Feedback type="invalid">{meta.error}</Form.Control.Feedback>
    </>
  );
};

const FormField = (props) => {
  const { maxlength, as, sm, label, name, type, Prepend, Append, required, autocomplete, validOn, onInput, isDisabled } = props;
  return (
    <Field name={name}>
      {({ field, meta }) => {
        const isValid = !meta.error;
        const isInvalid = meta.touched && !isValid;

        return (
          <Form.Group
            as={as}
            sm={sm}
            controlId={name}>
            {label && <Form.Label className={required ? "required" : null}>{label}</Form.Label>}
            <InputGroupWrapper
              Prepend={Prepend}
              Append={Append}
              meta={meta}>
              <Form.Control
                {...field}
                type={type}
                size="lg"
                disabled={isDisabled}
                onInput={onInput}
                autoComplete={autocomplete}
                maxLength={maxlength}
                isValid={meta.touched && isValid && validOn}
                isInvalid={isInvalid && validOn}
                feedback={meta.error}
              />
            </InputGroupWrapper>
          </Form.Group>
        );
      }}
    </Field>
  );
};

FormField.defaultProps = {
  type: "text",
  Prepend: null,
  Append: null,
  required: false,
  autocomplete: "on",
  validOn: true,
  onInput: null,
  isDisabled: false,
};

export default FormField;
