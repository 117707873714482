/* eslint-disable no-unused-vars */
import React from "react";
import { FieldArray } from "formik";
import { Col, InputGroup, Row, Button, Stack } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";

import FormField from "components/Inputs/FormField";
import FormSelect from "components/Inputs/FormSelect";
import Suppliers from "components/forms/Suppliers";

const DeliveryStep = (props) => {
  const { values, handleNextStep, setOpenContractor, setOpenDriver, setOpenVehicle, setOpenRaw, isValid, isSubmitting, isEditable } = props;

  let navigate = useNavigate();

  const containers = {
    weighingProtocol: "",
    vehicle: "",
    containerNumber: "",
    raw: "",
    realWeight: "",
    hdiNumber: "",
    hdiDate: "",
    hdiComments: "",
    redirectRecipient: null,
    redirectDeliveryDate: "",

    suppliers: [
      {
        name: "",
        weight: "",
        snc: "",
      },
    ],
  };

  const suppliers = {
    name: "",
    weight: "",
    snc: "",
  };

  return (
    <Col
      xl="8"
      lg="auto">
      <Stack gap={2}>
        <Row>
          <Col sm="6">
            <FormSelect
              isDisabled={!isEditable}
              label="Przewoźnik"
              name="carrier"
              onListButtonClick={() => setOpenContractor(true)}
              dictionary="carrier"
            />
          </Col>
          <Col sm="6">
            <FormSelect
              isDisabled={!isEditable}
              label="Kierowca"
              required
              name="driver"
              onListButtonClick={() => setOpenDriver(true)}
              dictionary="driver"
            />
          </Col>
        </Row>
        <FieldArray name="containers">
          {({ insert, remove, push }) => (
            <Row>
              {values?.containers?.length > 0 &&
                values?.containers.map((container, index) => (
                  <Stack
                    gap={2}
                    key={index}>
                    <Row>
                      <Col>
                        <h3>Kontener #{index + 1}</h3>
                      </Col>
                    </Row>
                    <Row>
                      <FormField
                        isDisabled={!isEditable}
                        as={Col}
                        sm="12"
                        label="Protokół ważenia"
                        type="text"
                        name={`containers.${index}.weighingProtocol`}
                      />
                    </Row>
                    <Row>
                      <Col sm="6">
                        <FormSelect
                          isDisabled={!isEditable}
                          label="Pojazd"
                          required
                          name={`containers.[${index}].vehicle`}
                          onListButtonClick={() => setOpenVehicle(true)}
                          dictionary="vehicle"
                        />
                      </Col>
                      <FormField
                        isDisabled={!isEditable}
                        as={Col}
                        sm="6"
                        required
                        label="Numer kontenera"
                        onInput={(e) => (e.target.value = e.target.value.toUpperCase())}
                        type="text"
                        name={`containers.${index}.containerNumber`}
                      />
                    </Row>
                    <Row>
                      <Col sm="6">
                        <FormSelect
                          isDisabled={!isEditable}
                          label="Surowiec"
                          required
                          name={`containers.${index}.rawMaterial`}
                          onListButtonClick={() => setOpenRaw(true)}
                          dictionary="rawMaterial"
                        />
                      </Col>
                      <FormField
                        isDisabled={!isEditable}
                        as={Col}
                        sm="6"
                        label="Waga rzeczywista"
                        type="text"
                        name={`containers.${index}.realWeight`}
                        Append={<InputGroup.Text id="inputGroupPrepend">KG</InputGroup.Text>}
                      />
                    </Row>
                    <Row>
                      <Suppliers
                        isEditable={isEditable}
                        name={`containers.${index}.suppliers`}
                        suppliers={suppliers}
                        setOpen={() => setOpenContractor(true)}
                        container={container}
                      />
                    </Row>
                    <Col>
                      <Row>
                        {isEditable && (
                          <Stack
                            direction="horizontal"
                            gap={3}>
                            {handleNextStep && ((index === 0 && values?.containers?.length !== 2) || index > 0) && (
                              <Stack
                                direction="horizontal"
                                gap={3}>
                                <Button
                                  variant="primary"
                                  onClick={() => handleNextStep(values)}
                                  disabled={!isValid || isSubmitting}>
                                  Kontynuuj
                                </Button>
                                <Button
                                  variant="danger"
                                  onClick={() => navigate("/")}>
                                  Anuluj
                                </Button>
                              </Stack>
                            )}
                            {index > 0 && (
                              <Button
                                variant="danger"
                                onClick={() => remove(index)}>
                                Usuń kontener
                              </Button>
                            )}
                            {index === 0 && values?.containers?.length !== 2 && (
                              <Button
                                variant="primary"
                                onClick={() => push(containers)}>
                                Dodaj kontener
                              </Button>
                            )}
                          </Stack>
                        )}
                      </Row>
                    </Col>
                  </Stack>
                ))}
            </Row>
          )}
        </FieldArray>
      </Stack>
    </Col>
  );
};

export default DeliveryStep;

DeliveryStep.defaultProps = {
  isEditable: true,
};
