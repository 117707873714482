/* eslint-disable indent */
/* eslint-disable quotes */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { useDictionariesData } from "queries/useDictionaryData";

import { useField } from "formik";
import { Button, Form } from "react-bootstrap";
import Select, { components } from "react-select";
import AuthService from "../../../services/auth.service";

function FormSelect({
  validOn,
  callback,
  as,
  sm,
  required,
  label,
  onListButtonClick,
  dictionary,
  options,
  isMulti,
  defaultValue,
  isDisabled,
  ...props
}) {
  const [field, meta, { setValue, setTouched }] = useField(props);
  const [validClass, setValidClass] = useState("");
  const [dictionaryID, setDictionaryID] = useState(1);
  const [optionLabel, setOptionLabel] = useState(options || []);
  const [_default, setDef] = useState();

  const onChange = (value) => {
    isMulti
      ? setValue(value.map((item) => item.value))
      : setValue(value?.value);
    if (callback !== undefined) {
      callback();
    }
  };

  useEffect(() => {
    if (meta.touched && meta.error && validOn) {
      setValidClass("is-invalid");
    } else if (meta.touched && !meta.error && validOn) {
      setValidClass("valid");
    } else {
      setValidClass("");
    }
  }, [meta]);

  useEffect(() => {
    if (defaultValue !== undefined) {
      setDef(defaultValue);
    }
  }, [defaultValue]);

  useEffect(() => {
    switch (dictionary) {
      case "vehicle":
        return setDictionaryID(1);
      case "driver":
        return setDictionaryID(2);
      case "rawMaterial":
        return setDictionaryID(3);
      case "recipient":
        return setDictionaryID(4);
      case "carrier":
        return setDictionaryID(4);
      case "supplier":
        return setDictionaryID(4);
    }
  }, []);

  const { data, isLoading } = useDictionariesData(dictionaryID);

  const handleContractor = (data, dictionary) => {
    const _data = data?.map((s) => ({
      label:
        JSON.parse(s.body)?.shortName + " - " + JSON.parse(s.body)?.fullName,
      value: s.id,
      contractorType: JSON.parse(s.body)?.contractorType,
    }));
    return _data?.filter((item) =>
      item.contractorType?.find((contract) => contract === dictionary)
    );
  };

  useEffect(() => {
    if (!isLoading) {
      switch (dictionary) {
        case "vehicle":
          return setOptionLabel(
            data?.map((s) => ({
              label: JSON.parse(s.body)?.regNumber,
              value: s.id,
            }))
          );
        case "driver":
          return setOptionLabel(
            data?.map((s) => ({
              label:
                JSON.parse(s.body)?.surname + " " + JSON.parse(s.body)?.name,
              value: s.id,
            }))
          );
        case "rawMaterial":
          return setOptionLabel(
            data?.map((s) => ({
              label: JSON.parse(s.body)?.shortName,
              value: s.id,
            }))
          );
        case "recipient":
          return setOptionLabel(handleContractor(data, dictionary));
        case "carrier":
          return setOptionLabel(handleContractor(data, dictionary));
        case "supplier":
          return setOptionLabel(handleContractor(data, dictionary));
      }
    }
  }, [data]);

  const Checkbox = ({ children, ...props }) => (
    <label style={{ marginRight: "1em" }}>
      <input type="checkbox" {...props} />
      {children}
    </label>
  );

  return (
    <Form.Group as={as} sm={sm} className="mb-3">
      {label && (
        <Form.Label className={required ? "required" : null}>
          {label}
        </Form.Label>
      )}
      <Select
        components={{ Menu: SelectMenuButton }}
        onListButtonClick={onListButtonClick}
        dictionaryID={dictionaryID}
        value={
          _default !== undefined
            ? findDefaultOptions(optionLabel, field)
            : optionLabel?.find((option) => option?.value === field?.value)
        }
        defaultValue={
          _default !== undefined
            ? findDefaultOptions(optionLabel, field)
            : optionLabel?.find((option) => option?.value === field?.value)
        }
        placeholder=""
        isMulti={isMulti}
        isClearable={true}
        backspaceRemovesValue
        isLoading={isLoading}
        isDisabled={isDisabled}
        classNamePrefix="form-select"
        className={validClass}
        options={optionLabel}
        onChange={onChange}
        onBlur={setTouched}
      />
      {/* {meta.touched && meta.error ? (
        <div className="form-text text-danger">{meta.error}</div>
      ) : null} */}

      {(meta.touched && meta.error) ||
      (meta.touched && meta.value === undefined && validOn) ? (
        <div className="form-text text-danger">{meta.error}</div>
      ) : null}
    </Form.Group>
  );
}
export default FormSelect;

FormSelect.defaultProps = {
  onListButtonClick: null,
  callback: undefined,
  isMulti: false,
  validOn: true,
  isDisabled: false,
};

const SelectMenuButton = (props) => {
  const [buttonTitle, setButtonTitle] = useState("");
  const onClick = props.selectProps.onListButtonClick;
  const ID = props.selectProps.dictionaryID;

  useEffect(() => {
    switch (ID) {
      case 1:
        return setButtonTitle("nowy pojazd");
      case 2:
        return setButtonTitle("nowego kierowcę");
      case 3:
        return setButtonTitle("nowy surowiec");
      case 4:
        return setButtonTitle("nowego kontrahenta");
      default:
        console.log("nie podano rodzaju słownika");
    }
  }, []);

  let canEdit = false;
  if (ID === 1 && AuthService.userCan("edytuje_pojazdy")) {
    canEdit = true;
  }
  if (ID === 2 && AuthService.userCan("edytuje_kierowcow")) {
    canEdit = true;
  }
  if (ID === 3 && AuthService.userCan("edytuje_surowce")) {
    canEdit = true;
  }
  if (ID === 4 && AuthService.userCan("edytuje_kontrahentow")) {
    canEdit = true;
  }
  return (
    <components.Menu {...props}>
      {props.children}
      {onClick && canEdit && (
        <div className="d-grid">
          <Button variant="light" onClick={() => onClick()} size="small">
            + Dodaj {buttonTitle}
          </Button>
        </div>
      )}
    </components.Menu>
  );
};

const findDefaultOptions = (options, field) => {
  return options.filter((option) => field.value.includes(option.value));
};
