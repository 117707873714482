import React, { useEffect, useState } from "react";
import { Modal, Button, Stack, Badge, Form as BForm } from "react-bootstrap";
import { Formik, Form, Field } from "formik";
import FormRatings from "form-ratings";
import FormField from "components/Inputs/FormField";
import FormSelect from "components/Inputs/FormSelect";
import * as yup from "yup";

import FormDebug from "components/debug/FormDebug";
import DatePickerField from "components/Inputs/DataTime";
import Icons from "components/Icons/";
import { useUpdateStatusTime } from "queries/useStatusTime";

const ChangeStatusTime = (props) => {
  const { onHide, statusdata } = props;

  const { mutate } = useUpdateStatusTime();

  const [initialValues, setInitialValues] = useState({});

  useEffect(() => {
    if (statusdata?.type !== undefined) {
      switch (true) {
        case statusdata.type === 6 && statusdata.acceptanceType === 4:
          setInitialValues({
            id: statusdata?.state_change_history[statusdata?.state_change_history.length - 1]?.id,
            created_at: statusdata?.state_change_history[statusdata?.state_change_history.length - 1]?.created_at,
            freshnessQuality: statusdata.freshnessQuality,
            bloodQuantity: statusdata.bloodQuantity,
            waterQuantity: statusdata.waterQuantity,
            foreignBodies: statusdata.foreignBodies,
            foreignBodiesComment: statusdata.foreignBodiesComment,
          });
          break;
        case statusdata.type === 5 && statusdata.acceptanceType === 5:
          setInitialValues({
            id: statusdata?.state_change_history[statusdata?.state_change_history.length - 1]?.id,
            created_at: statusdata?.state_change_history[statusdata?.state_change_history.length - 1]?.created_at,
            place: statusdata?.place,
          });
          break;
        case statusdata.type === 7 && statusdata.acceptanceType === 6:
          setInitialValues({
            id: statusdata?.state_change_history[statusdata?.state_change_history.length - 1]?.id,
            created_at: statusdata?.state_change_history[statusdata?.state_change_history.length - 1]?.created_at,
            removalRecipient: statusdata?.removalRecipient,
            removalComments: statusdata?.removalComments,
          });
          break;
        default:
          setInitialValues({
            id: statusdata?.state_change_history[statusdata?.state_change_history.length - 1]?.id,
            created_at: statusdata?.state_change_history[statusdata?.state_change_history.length - 1]?.created_at,
          });
          break;
      }
    }
  }, [statusdata]);

  const validationSchema = yup.object({
    id: yup.number(),
    created_at: yup.string().required("Pole wymagane"),
  });

  return (
    <Modal {...props} onHide={onHide} aria-labelledby='contained-modal-title-vcenter' centered>
      <Modal.Header closeButton>
        <Modal.Title className='d-flex align-middle' id='contained-modal-title-vcenter'>
          <Icons.Warning /> <span className='modal-title'>Edytować ostatni status?</span>
        </Modal.Title>
      </Modal.Header>

      <Formik
        initialValues={initialValues}
        enableReinitialize
        validateOnMount
        validationSchema={validationSchema}
        onSubmit={(values) => {
          mutate(values);
          onHide();
        }}>
        {({ values, isValid }) => (
          <Form>
            <Modal.Body>
              <div>
                <p>
                  Kontener # {statusdata.containerNumber}: ({statusdata?.rawMaterialString !== undefined ? JSON.parse(statusdata.rawMaterialString).shortName : ""})
                </p>
                <ul>
                  {statusdata?.suppliers?.map((supplier, index) => {
                    return (
                      <li key={index}>
                        {supplier.weight}kg od {supplier?.nameString !== undefined ? JSON.parse(supplier.nameString).shortName : ""}
                        <Badge bg='warning' text='dark'>
                          {supplier.snc}
                        </Badge>
                      </li>
                    );
                  })}
                </ul>
              </div>

              <Stack gap={3} className='p-2'>
                <DatePickerField required label='Data i czas operacji' name='created_at' />
                {initialValues.place && <FormField required label='Miejsce' type='text' name='place' />}
                {initialValues.removalComments && (
                  <>
                    <FormSelect label='Odbiorca' required dictionary='recipient' name='removalRecipient' />
                    <FormField required label='Uwagi' type='text' name='removalComments' />
                  </>
                )}
                {initialValues.freshnessQuality && (
                  <Stack gap={3}>
                    <Stack direction='horizontal' gap={4}>
                      <label className='required' htmlFor='freshnessQuality'>
                        Świeżość:
                      </label>
                      <Field name='freshnessQuality' className='ms-1' as={FormRatings} />
                    </Stack>
                    <Stack direction='horizontal' gap={4}>
                      <label className='required' htmlFor='bloodQuantity'>
                        Ilość krwi:
                      </label>
                      <Field name='bloodQuantity' as={FormRatings} />
                    </Stack>
                    <Stack direction='horizontal' gap={4}>
                      <label className='required' htmlFor='waterQuantity'>
                        Ilość wody:
                      </label>
                      <Field name='waterQuantity' as={FormRatings} />
                    </Stack>
                    <div role='group' aria-labelledby='my-radio-group'>
                      <Stack direction='horizontal' gap={3}>
                        <label className='required' htmlFor='foreignBodies'>
                          Ciała obce?
                        </label>
                        <Stack direction='horizontal' gap={3}>
                          <Field as={BForm.Check} type='radio' name='foreignBodies' value={"true"} />
                          Tak
                        </Stack>
                        <Stack direction='horizontal' gap={3}>
                          <Field as={BForm.Check} type='radio' name='foreignBodies' value={"false"} />
                          Nie
                        </Stack>
                      </Stack>
                    </div>
                    {values.foreignBodies === "true" && <FormField label='Rodzaj ciał obcych' type='text' name='foreignBodiesComment' />}
                  </Stack>
                )}
              </Stack>
              <FormDebug values={values} />
            </Modal.Body>
            <Modal.Footer>
              <Button type='submit' disabled={!isValid}>
                Ok
              </Button>
              <Button onClick={onHide}>Anuluj</Button>
            </Modal.Footer>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

export default ChangeStatusTime;
