import { useQuery, useMutation, useQueryClient } from "react-query";
import { request } from "../utils/axios";
import toast from "react-hot-toast";
import { useContext } from "react";
import { WebsocketContext } from "context/WebsocketContext";

const fetchUsers = () => {
  return request({ url: "/users", method: "get" });
};

export const useUsersData = () => {
  return useQuery(["users"], () => fetchUsers(), {
    refetchOnWindowFocus: false,
    onError: (error) => {
      toast.error(`Error on background update: ${error.message}`);
    },
    select: (data) => {
      const User = data?.data?.data;
      return User;
    },
  });
};

const addUserItem = (data) => {
  return request({ url: "/users", method: "post", data: data });
};

export const useAddUserData = () => {
  const queryClient = useQueryClient();
  const [ready, send] = useContext(WebsocketContext);

  return useMutation(addUserItem, {
    onError: (error) => {
      toast.error(`Error on background update: ${error.message}`);
    },
    onSuccess: () => {
      queryClient.invalidateQueries(["users"]);

      if (ready) {
        send(JSON.stringify({ operation: "invalidate", entity: ["users"] }));
      }
    },
  });
};

const updateUserItem = (data) => {
  return request({ url: `users/${data.id}`, method: "patch", data: data });
};

export const useUpdateUserData = () => {
  const queryClient = useQueryClient();
  const [ready, send] = useContext(WebsocketContext);

  return useMutation(updateUserItem, {
    onError: (error) => {
      toast.error(`Error on background update: ${error.message}`);
    },
    onSuccess: () => {
      queryClient.invalidateQueries(["users"]);

      if (ready) {
        send(JSON.stringify({ operation: "invalidate", entity: ["users"] }));
      }
    },
  });
};

const deleteUserItem = (id) => {
  return request({
    url: `users/${id}`,
    method: "delete",
  });
};

export const useDeleteUserData = () => {
  const queryClient = useQueryClient();
  const [ready, send] = useContext(WebsocketContext);

  return useMutation(deleteUserItem, {
    onError: (error) => {
      toast.error(`Error on background update: ${error.message}`);
    },
    onSuccess: () => {
      queryClient.invalidateQueries(["users"]);

      if (ready) {
        send(JSON.stringify({ operation: "invalidate", entity: ["users"] }));
      }
    },
  });
};
