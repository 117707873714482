/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import BTable from "react-bootstrap/Table";
import { useTable, usePagination } from "react-table";

// eslint-disable-next-line no-unused-vars
const Table = ({ columns, data, loading, pageCount: controlledPageCount, setPage, pageNumber }) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    footerGroups,
    canPreviousPage,
    canNextPage,
    pageOptions,
    gotoPage,
    nextPage,
    previousPage,
    pageCount,
    state: { pageIndex },
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: pageNumber, pageSize: 10 },
      manualPagination: true,
      pageCount: controlledPageCount,
    },
    usePagination
  );

  const onClickDoubleArrow = (direction) => {
    const amountOfPagesToMove = 5;

    if (direction === "prev") {
      if (pageNumber - amountOfPagesToMove <= 0) {
        gotoPage(0);
        setPage(0);
      } else {
        gotoPage(pageNumber - amountOfPagesToMove);
        setPage(pageNumber - amountOfPagesToMove);
      }
    } else {
      if (pageNumber + amountOfPagesToMove >= pageCount) {
        gotoPage(pageCount - 1);
        setPage(pageCount - 1);
      } else {
        gotoPage(pageNumber + amountOfPagesToMove);
        setPage(pageNumber + amountOfPagesToMove);
      }
    }
  };

  return (
    <div>
      <BTable style={{ height: "1px" }} striped bordered hover size='md' {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup, i) => (
            <tr key={i} {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column, i) => (
                <th key={i} {...column.getHeaderProps([{ style: column.style }])} className={column?.className ? `table-header ${column.className}` : "table-header"}>
                  {column.render("Header")}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row, i) => {
            prepareRow(row);
            return (
              <tr key={i} {...row.getRowProps()}>
                {row.cells.map((cell, i) => {
                  return (
                    <td
                      className='align-middle'
                      key={i}
                      {...cell.getCellProps({
                        className: cell.column.className,
                        style: cell.column.style,
                      })}>
                      <div style={{ display: "flex", flexDirection: "column", height: "100%", justifyContent: "center" }}>{cell.render("Cell")}</div>
                    </td>
                  );
                })}
              </tr>
            );
          })}
          <tr>{loading && <td colSpan='10000'>Loading...</td>}</tr>
        </tbody>
        <tfoot>
          {footerGroups.map((group, i) => (
            <tr key={i} {...group.getFooterGroupProps()}>
              {group.headers.map((column, i) => (
                <td key={i} {...column.getFooterProps()}>
                  {column.render("Footer")}
                </td>
              ))}
            </tr>
          ))}
        </tfoot>
      </BTable>
      <nav aria-label='...' className='d-flex align-items-center mb-3' style={{ justifyContent: "space-between", gap: "20px" }}>
        <ul className='pagination mb-0'>
          <li className={`page-item ${!canPreviousPage ? "disabled" : ""}`}>
            <button
              className='page-link'
              onClick={() => {
                onClickDoubleArrow("prev");
              }}
              disabled={!canPreviousPage}>
              {"<<"}
            </button>
          </li>
          <li className={`page-item ${!canPreviousPage ? "disabled" : ""}`}>
            <button
              className='page-link'
              onClick={() => {
                previousPage();
                setPage(pageNumber - 1);
              }}
              disabled={!canPreviousPage}>
              {"<"}
            </button>
          </li>
          {pageOptions.map((page, i) => {
            if (page < 5 || page > pageCount - 5 || (page >= pageIndex - 2 && page <= pageIndex + 2)) {
              return (
                <li key={i} className={`page-item ${page === pageIndex ? "disabled" : ""}`}>
                  <button
                    className='page-link'
                    onClick={() => {
                      gotoPage(page);
                      setPage(page);
                    }}>
                    {page + 1}
                  </button>
                </li>
              );
            } else if (page === 5 || page === pageCount - 5) {
              return (
                <li key={i} className='page-item disabled'>
                  <button className='page-link'>...</button>
                </li>
              );
            } else {
              return null;
            }
          })}

          <li className={`page-item ${!canNextPage ? "disabled" : ""}`}>
            <button
              className='page-link'
              onClick={() => {
                nextPage();
                setPage(pageNumber + 1);
              }}
              disabled={!canNextPage}>
              {">"}
            </button>
          </li>
          <li className={`page-item ${!canNextPage ? "disabled" : ""}`}>
            <button
              className='page-link'
              onClick={() => {
                onClickDoubleArrow("next");
              }}
              disabled={!canNextPage}>
              {">>"}
            </button>
          </li>
        </ul>

        <div className='ml-3' style={{ whiteSpace: "nowrap" }}>
          <span>
            Strona{" "}
            <strong>
              {pageIndex + 1} of {pageOptions.length}
            </strong>{" "}
          </span>
        </div>

        <div className='input-group input-group-sm' style={{ width: "222", flexWrap: "nowrap" }}>
          <div className='input-group-prepend'>
            <span className='input-group-text'>Idź do strony:</span>
          </div>

          <input
            className='form-control text-center '
            type='number'
            defaultValue={pageIndex + 1}
            min={1}
            max={pageOptions.length}
            onChange={(e) => {
              const page = e.target.value ? Number(e.target.value) - 1 : 0;
              gotoPage(page);
              setPage(page);
            }}
            style={{ width: "100px", maxWidth: "100px", maxHeight: "38px" }}
          />
        </div>
      </nav>
    </div>
  );
};

export default Table;
