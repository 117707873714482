/* eslint-disable no-unused-vars */
import { useQuery, useMutation, useQueryClient } from "react-query";
import { request } from "utils/axios";
import toast from "react-hot-toast";
import { useContext } from "react";
import { WebsocketContext } from "../context/WebsocketContext";

const types = ["vehicle", "driver", "rawMaterial", "contractor"];

const fetchDictionaries = (typeID) => {
  return request({ url: `/dictionaries?type=${typeID}`, method: "get" });
};

export const useDictionariesData = (typeID) => {
  return useQuery(["dictionaries", types[typeID - 1]], () => fetchDictionaries(typeID), {
    refetchOnWindowFocus: false,
    onError: (error) => {
      toast.error(`Error on background update: ${error.message}`);
    },
    select: (data) => {
      const dictionary = data?.data?.data;
      return dictionary;
    },
  });
};

const fetchAllDictionaries = () => {
  return request({ url: "/dictionaries", method: "get" });
};

export const useAllDictionariesData = () => {
  return useQuery(["dictionaries"], () => fetchAllDictionaries(), {
    refetchOnWindowFocus: false,
    onError: (error) => {
      toast.error(`Error on background update: ${error.message}`);
    },
    select: (data) => {
      const dictionary = data?.data?.data;
      return dictionary;
    },
  });
};

const addDictionaryItem = (item) => {
  return request({ url: "/dictionaries", method: "post", data: item });
};

export const useAddDictionaryData = () => {
  const queryClient = useQueryClient();
  const [ready, send] = useContext(WebsocketContext);

  return useMutation(addDictionaryItem, {
    onError: (error) => {
      toast.error(`Error on background update: ${error.message}`);
    },
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries(["dictionaries", types[variables.type - 1]]);
      if (ready) {
        send(JSON.stringify({ operation: "invalidate", entity: ["dictionaries"] }));
      }
    },
  });
};

const updateDictionaryItem = (data) => {
  return request({ url: `dictionaries/${data.id}`, method: "patch", data: data.data });
};

export const useUpdateDictionaryData = () => {
  const queryClient = useQueryClient();
  const [ready, send] = useContext(WebsocketContext);

  return useMutation(updateDictionaryItem, {
    onError: (error) => {
      toast.error(`Error on background update: ${error.message}`);
    },
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries(["dictionaries", types[variables.data.type - 1]]);
      if (ready) {
        send(JSON.stringify({ operation: "invalidate", entity: ["dictionaries"] }));
      }
    },
  });
};

const deleteDictionaryItem = (data) => {
  return request({
    url: `dictionaries/${data.id}`,
    method: "delete",
  });
};

export const useDeleteDictionaryData = () => {
  const queryClient = useQueryClient();
  const [ready, send] = useContext(WebsocketContext);

  return useMutation(deleteDictionaryItem, {
    onError: (error) => {
      toast.error(`Error on background update: ${error.message}`);
    },
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries(["dictionaries", types[variables.data.type - 1]]);
      if (ready) {
        send(JSON.stringify({ operation: "invalidate", entity: ["dictionaries"] }));
      }
    },
  });
};
