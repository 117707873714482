import React from "react";
import Icons from "components/Icons";

import { ErrorWrapper } from "./style.js";

const ApiError = (props) => {
  const { error } = props;
  return (
    <ErrorWrapper>
      <Icons.Error />
      {error.message}
    </ErrorWrapper>
  );
};

export default ApiError;
