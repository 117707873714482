import React from "react";
import { Badge } from "react-bootstrap";
import { Link } from "react-router-dom";

export const HDIBadge = ({ row }) => {
  const hdiDates = row.original.containers.map((container) => container.suppliers.map((supplier) => supplier.hdiDate));

  const hdiDatesArray = hdiDates.flat();

  const hdiDatesArrayFiltered = hdiDatesArray.filter((hdiDate) => hdiDate !== null);

  if (hdiDatesArrayFiltered.length > 0) {
    return (
      <Link
        className='btn btn-sm btn-link'
        to={{
          pathname: `/delivery/create/edit/${row.values.id}/#hdi`,
        }}>
        <Badge bg='dark'>HDI</Badge>
      </Link>
    );
  } else {
    return null;
  }
};
