/* eslint-disable no-unused-vars */
import React from "react";

import * as yup from "yup";

import FormField from "components/Inputs/FormField";
import ModalWrapper from "../Template/";
import { useAddDictionaryData } from "queries/useDictionaryData";

const Driver = (props) => {
  const { onHide } = props;

  const { mutate } = useAddDictionaryData();

  const initialValues = {
    surname: "",
    name: "",
  };

  const schema = yup.object({
    surname: yup.string().required("Pole wymagane"),
    name: yup.string().required("Pole wymagane"),
  });

  const onSubmit = (values) => {
    mutate({ type: 2, body: JSON.stringify(values) });
  };

  return (
    <ModalWrapper {...props} schema={schema} initial={initialValues} onSubmit={(values) => onSubmit(values)} title='Rejestracja kierowcy' onHide={onHide}>
      <FormField required label='Nazwisko' type='text' name='surname' />
      <FormField required label='Imię' type='text' name='name' />
    </ModalWrapper>
  );
};

export default Driver;
